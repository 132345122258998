:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main-container-mob {
    overflow: hidden;
  }
  
 
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
   
   

   
  
 
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    background-color: #ffffff88 !important;
  }
  .css-10hburv-MuiTypography-root {
    font-weight: 700 !important;
    font-size: 20px !important;
  }

  .main-container-mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    /* gap: 40px; */
    position: relative;
    margin: 0 auto;
    background: #fff;
    overflow: hidden;
  }
  .header-body-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    margin-bottom: 10px;
  }
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 0 16px;
    z-index: 1;
    background-color: inherit;

 

  }
  .menu-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    padding: 20px !important;
    background: #fff;
    z-index: 2;
    border-radius: 100px;
     

  }
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    background-color: #fff !important;
    /* filter: blur(1px); */


  }
  

  .MuiDrawer-paper {
    width: 100%;
    background-color: #fff; 
    backdrop-filter: blur(90px);  
   }

 
.drawer-menu-text:hover{
    color:#ee6931
}

  .frank-logo {
    flex-shrink: 0;
    position: relative;
    width: 64.098px;
    height: 42px;
     
    background-size: cover;
    z-index: 3;
  }
  .line-menu-line {
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 32px;
    z-index: 4;
    overflow: hidden;
  }


  .icon {
    position: relative;
    width: 26.667px;
    height: 18.668px;
    margin: 6.666px 0 0 2.667px;
    background: url("../../assets/mobileImages/01399a90-67de-4279-8067-7c6e3a4c6115.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 5;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 60px;
    position: relative;
    z-index: 6;
  }
  .top-section-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    padding: 30px;
    background: #fff;
    z-index: 7;
    border-radius: 32px;
    height: 100%;
    margin-top: 15px;
  margin:10px
  }
  .vector {
    flex-shrink: 0;
    position: absolute;
    width: 1227.14px;
    height: 995.673px;
    top: 154px;
    left: -286px;
    background: url("../../assets/mobileImages/c0f0f882-067a-4813-9827-f518c76f1758.png")
      no-repeat center;
    background-size: cover;
    z-index: 8;
  }
  .envoyez-vos-colis-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 295px;
    height: 108px;
    color: #000;
    font-family: "Raleway-Variable";
    font-size: 32px;
    /* font-weight: 700; */
    line-height: 36px;
    text-align: left;
    z-index: 9;
  }
  .frame_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 10;
  }
  .frame-1_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 24px 24px 24px 24px;
    background: #3026f1;
    z-index: 11;
    overflow: hidden;
    border-radius: 32px;
  }
  .frame-2_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 12;
  }
  .envoyer-un-colis_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 32px;
    color: #080626;
    font-family: "Raleway-Italic";
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    white-space: nowrap;
    z-index: 13;
    width: 100%;
  }
  .et-nisi-leo_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 247px;
    height: 32px;
    color: #626175;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    z-index: 14;
  }
  .frame-3_mob {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 15;
  }
  .frame-4_mob {
    flex-shrink: 0;
    position: relative;
    width: 149px;
    height: 90px;
    background: url("../../assets/mobileImages/d1e8eb01addbd9c70190f291b253845134a0e1fb.png")
      no-repeat center;
    background-size: cover;
    z-index: 16;
  }
  .frame-5_mob {
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 48px;
    z-index: 17;
  }
  .frame-6_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 12px 12px 12px 12px;
    background: #fff;
    z-index: 18;
    border-radius: 100px;
  }
  .line-arrow-right_mob {
    flex-shrink: 0;
    position: relative;
    width: 35px;
    height: 35px;
    z-index: 19;
    display: flex;
    justify-self: center;
    align-items: center;
    overflow: hidden;
  }
  .icon-7_mob {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
    background: url("../../assets/mobileImages/21c2f4e2-dad8-4a4f-939f-16cb4c27021f.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 20;
  }
  .frame-8_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 24px 24px 24px 24px;
    background: #ffffff;
    z-index: 21;
    overflow: hidden;
    border-radius: 32px;
  }
  .frame-9_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 22;
  }
  .transporter-un-colis_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 32px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    white-space: nowrap;
    z-index: 23;
    width: 100%;
  }
  .et-nisi-leo-a_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 247px;
    height: 32px;
    color: #626175;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    z-index: 24;
  }
  .frame-b_mob {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 25;
  }
  .frame-c_mob {
    flex-shrink: 0;
    position: relative;
    width: 166.595px;
    height: 90px;
    background: url("../../assets/mobileImages/41bb2f120892f710867375bfa331cf74ced98201.png")
      no-repeat center;
    background-size: cover;
    z-index: 26;
  }
  .frame-d_mob {
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 48px;
    z-index: 27;
  }
  .frame-e_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    /* width: 48px; */
    padding: 12px 12px 12px 12px;
    background: #fce6dd;
    z-index: 28;
    border-radius: 100px;
  }
  .line-arrow-right-f_mob_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 29;
    overflow: hidden;
  }
  .icon-10_mob {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 4px 0 0 4px;
    background: url("../../assets/mobileImages/3db6cd14-ee45-4ca9-abce-51fe5796c20a.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 30;
  }
  .frame-11_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 183px;
    z-index: 31;
  }
  .je-demenage_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 32;
  }
  .cest-par-ici_mob {
    flex-shrink: 0;
    position: relative;
    width: 83px;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 33;
  }
  .cest-par-ici-12_mob {
    position: relative;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-decoration: underline;
  }
  .why_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    min-width: 0;
    /* padding: 0 16px 0 16px; */
    padding-left: 10px;
    padding-right: 10px;
    z-index: 34;
    overflow: hidden;
  }
  .menu_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 35;
    justify-content: center;
    width: 100%;
  }
  .achetez-vos-produits_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    /* width: 364px; */
    height: 80px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-overflow: initial;
    z-index: 36;
    overflow: hidden;
  }
  .soyez-livres_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    text-align: center;
    /* width: 265px; */
    height: 24px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    z-index: 37;
  }
  .arguments_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    height: 100%;
    width: 100%;
    padding-right: 10px;
  }
  .frame-13_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 39;
  }
  .product_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 40;
    overflow: hidden;
    border-radius: 24px;
  }
  .img-container_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 120px;
    background: #ffffff;
    z-index: 41;
    overflow: hidden;
    border-radius: 16px;
  }
  .image_mob {
    position: absolute;
    width: 322px;
    height: 242px;
    top: -30px;
    left: 50%;
    background: url("../../assets/mobileImages/41b17007392e90981bfb8afba77b714b72ac9c21.png")
      no-repeat center;
    background-size: cover;
    transform: translate(-49.84%, 0);
    z-index: 42;
  }
  .frame-14_mob {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 43;
    overflow: hidden;
  }
  .title_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 139px;
    z-index: 44;
  }
  .table-en-bois_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 45;
  }
  .il-y-a_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 46;
  }
  .town-distance_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 102px;
    z-index: 47;
  }
  .aix-en-provence_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #281208;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 48;
  }
  .frame-15_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 65px;
    z-index: 49;
  }
  .solid-car_mob {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    z-index: 50;
    overflow: hidden;
  }
  .solid_mob {
    position: relative;
    width: 16.5px;
    height: 13.508px;
    margin: 2.25px 0 0 0.75px;
    background: url("../../assets/mobileImages/6e9cb50e-7e81-492b-9be6-b269aac0a790.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 51;
  }
  .km_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #78778a;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 52;
  }
  .frame-16_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 53;
    overflow: hidden;
    border-radius: 24px;
  }
  .img-container-17_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 120px;
    background: url("../../assets/mobileImages/d42cc43f-8ebe-4e5a-b9ce-4b1eb7e86bf1.png")
      no-repeat center;
    background-size: cover;
    z-index: 54;
    overflow: hidden;
    border-radius: 16px;
  }
  .frame-18_mob {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 55;
    overflow: hidden;
  }
  .title-19_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 139px;
    z-index: 56;
  }
  .table-en-bois-1a_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 57;
  }
  .il-y-a-1b_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 58;
  }
  .town-distance-1c_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 102px;
    z-index: 59;
  }
  .aix-en-provence-1d_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #281208;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 60;
  }
  .frame-1e_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 65px;
    z-index: 61;
  }
  .solid-car-1f_mob {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    z-index: 62;
    overflow: hidden;
  }
  .solid-20_mob {
    position: relative;
    width: 16.5px;
    height: 13.508px;
    margin: 2.25px 0 0 0.75px;
    background: url("../../assets/mobileImages/6fba0a6c-094c-485f-9bc1-af19a1e82cee.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 63;
  }
  .km-21_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #78778a;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 64;
  }
  .frame-22_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 65;
  }
  .product-23_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 66;
    overflow: hidden;
    border-radius: 24px;
  }
  .img-container-24_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 120px;
    background: url("../../assets/mobileImages/f0eda029-9d8c-4425-8d7f-8d9dbba57d72.png")
      no-repeat center;
    background-size: cover;
    z-index: 67;
    overflow: hidden;
    border-radius: 16px;
  }
  .frame-25_mob {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 68;
    overflow: hidden;
  }
  .title-26_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 139px;
    z-index: 69;
  }
  .table-en-bois-27_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 70;
  }
  .il-y-a-28_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 71;
  }
  .town-distance-29_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 102px;
    z-index: 72;
  }
  .aix-en-provence-2a_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #281208;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 73;
  }
  .frame-2b_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 65px;
    z-index: 74;
  }
  .solid-car-2c_mob {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    z-index: 75;
    overflow: hidden;
  }
  .solid-2d_mob {
    position: relative;
    width: 16.5px;
    height: 13.508px;
    margin: 2.25px 0 0 0.75px;
    background: url("../../assets/mobileImages/db8f00bc-381e-4181-bb0d-c3cab12745fe.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 76;
  }
  .km-2e_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #78778a;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 77;
  }
  .frame-2f_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 78;
    overflow: hidden;
    border-radius: 24px;
  }
  .img-container-30_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 120px;
    background: #ffffff;
    z-index: 79;
    overflow: hidden;
    border-radius: 16px;
  }
  .image-31_mob {
    position: absolute;
    width: 322px;
    height: 242px;
    top: -30px;
    left: 50%;
    background: url("../../assets/mobileImages/41b17007392e90981bfb8afba77b714b72ac9c21.png")
      no-repeat center;
    background-size: cover;
    transform: translate(-49.84%, 0);
    z-index: 80;
  }
  .frame-32_mob {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    z-index: 81;
    overflow: hidden;
  }
  .title-33_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 139px;
    z-index: 82;
  }
  .table-en-bois-clair_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 83;
  }
  .il-y-a-minutes_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 84;
  }
  .town-distance-34_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    width: 102px;
    z-index: 85;
  }
  .aix-en-provence-35_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #281208;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 86;
  }
  .frame-36_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 65px;
    z-index: 87;
  }
  .solid-car-37_mob {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    z-index: 88;
    overflow: hidden;
  }
  .solid-38_mob {
    position: relative;
    width: 16.5px;
    height: 13.508px;
    margin: 2.25px 0 0 0.75px;
    background: url("../../assets/mobileImages/b00183a1-19cc-4925-97ff-1ab8d675ea6c.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 89;
  }
  .km-39_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #78778a;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 90;
  }
  .button_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 190px;
    padding: 10px 24px 10px 24px;
    background: #2d26e6;
    z-index: 91;
    border-radius: 12px;
  }
  .voir-tous-les-produits_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 92;
  }
  .products-wrapper_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    min-width: 0;
    padding: 60px 16px 60px 16px;
    background: #f1cbb6;
    z-index: 93;
    overflow: hidden;
  }
  .vector-3a_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2335.467px;
    height: 1614.828px;
    top: 328.63px;
    left: -353.232px;
    background: url("../../assets/mobileImages/e8fa9b41-2787-4644-a382-f95194a1d8d7.png")
      no-repeat center;
    background-size: cover;
    z-index: 94;
  }
  .vector-3b_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2346.813px;
    height: 1635.432px;
    top: 167px;
    left: -362.001px;
    background: url("../../assets/mobileImages/2e0b19e1-29eb-4c7d-98ec-03a2798a36c8.png")
      no-repeat center;
    background-size: cover;
    z-index: 95;
  }
  .menu-3c_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 196px;
    z-index: 96;
  }
  .achetez-vos-produits-3d_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    /* width: 196px; */
    height: 80px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-overflow: initial;
    z-index: 97;
    overflow: hidden;
  }
  .story_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 98;
  }
  .img-wrapper_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    z-index: 99;
  }
  .device_mob {
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    height: 340px;
    border: 6.605px solid #e0e0e0;
    z-index: 103;
    border-radius: 16.242px;
  }
  .frame-3e_mob {
    flex-shrink: 0;
    position: absolute;
    width: 152.134px;
    height: 329.172px;
    top: 4.332px;
    left: 5.686px;
    background: #ffffff;
    z-index: 101;
    border-radius: 12.994px;
  }
  .rectangle_mob {
    position: relative;
    width: 181.911px;
    height: 231.178px;
    margin: 56.848px 0 0 -42.771px;
    background: url("../../assets/mobileImages/f778aba392d02426de373dfd5bb1d760c4081235.png")
      no-repeat center;
    background-size: cover;
    z-index: 102;
  }
  .top-speaker_mob {
    flex-shrink: 0;
    position: absolute;
    width: 169.459px;
    height: 326.465px;
    top: 33.025px;
    left: 37.164px;
    background: rgba(0, 0, 0, 0.08);
    filter: blur(16.242px);
    z-index: 104;
    border-radius: 12.994px;
  }
  .frame-3f_mob {
    flex-shrink: 0;
    position: absolute;
    height: 14.076px;
    top: 2.166px;
    right: 48.185px;
    left: 49.268px;
    background: url("../../assets/mobileImages/2413047a-3c03-4f35-8a59-61ec19215057.png")
      no-repeat center;
    background-size: cover;
    z-index: 100;
  }
  .inscription-et-creation-de-profil_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 105;
  }
  .inscription-et-creation-de-profil-40_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    /* width: 343px; */
    text-align: center;
    height: 64px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    text-overflow: initial;
    z-index: 106;
    overflow: hidden;
  }
  .creez-un-compte_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    /* width: 343px; */
    height: 60px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 107;
  }
  .voir-tous-les-produits-41_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 140px;
    padding: 10px 24px 10px 24px;
    background: #ef6a31;
    z-index: 108;
    border-radius: 12px;
  }
  .story-42_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 109;
  }
  .img-wrapper-43_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 110;
  }
  .device-44_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    z-index: 111;
  }
  .frame-45_mob {
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    height: 340px;
    border: 6.605px solid #e0e0e0;
    z-index: 115;
    border-radius: 16.242px;
  }
  .rectangle-46_mob {
    flex-shrink: 0;
    position: absolute;
    width: 152.134px;
    height: 329.172px;
    top: 4.332px;
    left: 5.684px;
    background: #ffffff;
    z-index: 113;
    border-radius: 12.994px;
  }
  .top-speaker-47_mob {
    position: relative;
    width: 196.529px;
    height: 224.14px;
    margin: 52.516px 0 0 -63.343px;
    background: url("../../assets/mobileImages/ec20a7fa2b94b62d2a3b0e9fcbfec8543d68719d.png")
      no-repeat center;
    background-size: cover;
    z-index: 114;
  }
  .frame-48_mob {
    flex-shrink: 0;
    position: absolute;
    width: 169.459px;
    height: 326.465px;
    top: 33.025px;
    left: 37.164px;
    background: rgba(0, 0, 0, 0.08);
    filter: blur(16.242px);
    z-index: 116;
    border-radius: 12.994px;
  }
  .publication-ou-selection-dune-livraison_mob {
    flex-shrink: 0;
    position: absolute;
    height: 14.076px;
    top: 2.166px;
    right: 48.181px;
    left: 49.271px;
    background: url("../../assets/mobileImages/35580d22-4091-4797-91ad-113d933f3e19.png")
      no-repeat center;
    background-size: cover;
    z-index: 112;
  }
  .publication-ou-selection-dune-livraison-49_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 117;
  }
  .publiez-une-demande_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    text-align: center;
    position: relative;
    /* width: 343px; */
    height: 64px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    z-index: 118;
  }
  .voir-tous-les-produits-4a_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    /* width: 343px; */
    height: 80px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 119;
  }
  .story-4b_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 140px;
    padding: 10px 24px 10px 24px;
    background: #ef6a31;
    z-index: 120;
    border-radius: 12px;
  }
  .img-wrapper-4c_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 121;
  }
  .device-4d_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 122;
  }
  .frame-4e_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    z-index: 123;
  }
  .rectangle-4f_mob {
    flex-shrink: 0;
    position: relative;
    width: 162.42px;
    height: 340px;
    border: 6.605px solid #e0e0e0;
    z-index: 127;
    border-radius: 16.242px;
  }
  .top-speaker-50_mob {
    flex-shrink: 0;
    position: absolute;
    width: 152.134px;
    height: 329.172px;
    top: 4.332px;
    left: 5.684px;
    background: #ffffff;
    z-index: 125;
    border-radius: 12.994px;
  }
  .frame-51_mob {
    position: relative;
    width: 249.045px;
    height: 243.089px;
    margin: 25.445px 0 0 -40.062px;
    background: url("../../assets/mobileImages/b6bb33c00f50668465232c9c05ae52ed477587ee.png")
      no-repeat center;
    background-size: cover;
    z-index: 126;
  }
  .suivi-et-reception-du-colis_mob {
    flex-shrink: 0;
    position: absolute;
    width: 169.459px;
    height: 326.465px;
    top: 33.025px;
    left: 37.164px;
    background: rgba(0, 0, 0, 0.08);
    filter: blur(16.242px);
    z-index: 128;
    border-radius: 12.994px;
  }
  .suivi-et-reception-du-colis-52_mob {
    flex-shrink: 0;
    position: absolute;
    height: 14.076px;
    top: 2.166px;
    right: 48.181px;
    left: 49.271px;
    background: url("../../assets/mobileImages/e5353432-bba6-474a-a390-6d742471be0b.png")
      no-repeat center;
    background-size: cover;
    z-index: 124;
  }
  .suivez-votre-colis_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 129;
  }
  .voir-tous-les-produits-53_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 32px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    white-space: nowrap;
    z-index: 130;
  }
  .why-54_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    text-align: center;
    /* width: 343px; */
    height: 80px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 131;
  }
  .achetez-vos-produits-55_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 140px;
    padding: 10px 24px 10px 24px;
    background: #ef6a31;
    z-index: 132;
    border-radius: 12px;
  }
  .frame-56_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 133;
  }
  .item_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    min-width: 0;
    padding: 0 16px 0 16px;
    z-index: 134;
    overflow: hidden;
  }
  .img-container-57_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    /* width: 343px; */
    height: 120px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-overflow: initial;
    z-index: 135;
    overflow: hidden;
  }
  .table_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 136;
  }
  .right_mob {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 137;
    overflow: hidden;
    border-radius: 32px;
  }
  .title-58_mob {
    align-self: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    background: #ffffff;
    z-index: 138;
    overflow: hidden;
    border-radius: 20px;
  }
  .table-en-bois-clair-59_mob {
    position: absolute;
    width: 211px;
    height: 158px;
    top: -3.67px;
    left: 50%;
    background: url("../../assets/mobileImages/41b17007392e90981bfb8afba77b714b72ac9c21.png")
      no-repeat center;
    background-size: cover;
    transform: translate(-51.07%, 0);
    z-index: 139;
  }
  .mise-en-ligne-il-y-a_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    height: 152px;
    z-index: 140;
  }
  .euros_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 141;
  }
  .item-5a_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 147.5px;
    height: 56px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    z-index: 142;
  }
  .img-container-5b_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 147.5px;
    height: 32px;
    color: #78778a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    z-index: 143;
  }
  .right-5c_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 40px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    z-index: 144;
  }
  .title-5d_mob {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 145;
    overflow: hidden;
    border-radius: 32px;
  }
  .table-en-bois-clair-5e_mob {
    align-self: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    background: url("../../assets/mobileImages/66c72593-1017-4291-af8b-0e79eaca27d4.png")
      no-repeat center;
    background-size: cover;
    z-index: 146;
    overflow: hidden;
    border-radius: 20px;
  }
  .mise-en-ligne-il-y-a-5f_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    height: 152px;
    z-index: 147;
  }
  .euros-60_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 148;
  }
  .item-61_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 149;
  }
  .img-container-62_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 147.5px;
    height: 32px;
    color: #78778a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    z-index: 150;
  }
  .right-63_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 40px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    z-index: 151;
  }
  .title-64_mob {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 152;
    overflow: hidden;
    border-radius: 32px;
  }
  .table-en-bois-clair-65_mob {
    align-self: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    background: url("../../assets/mobileImages/9ca9434b-5708-48b5-9246-06116303b898.png")
      no-repeat center;
    background-size: cover;
    z-index: 153;
    overflow: hidden;
    border-radius: 20px;
  }
  .mise-en-ligne-il-y-a-66_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    height: 152px;
    z-index: 154;
  }
  .group-13_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 155;
  }
  .text-2c_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 156;
  }
  .text-2d_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 147.5px;
    height: 32px;
    color: #78778a;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    z-index: 157;
  }
  .euro_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 40px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    z-index: 158;
  }
  .column_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    height: 369px;
    padding: 24px 24px 24px 24px;
    background: #2d26e6;
    z-index: 159;
    overflow: hidden;
    border-radius: 24px;
  }
  .vector-67_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2507.181px;
    height: 1972.535px;
    top: 0;
    left: -553.999px;
    background: url("../../assets/mobileImages/ff5b7b95-e869-4c95-a337-61ca26ebeb69.png")
      no-repeat center;
    background-size: cover;
    z-index: 160;
  }
  .vector-68_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2589.933px;
    height: 2290.814px;
    top: 12px;
    left: -867.999px;
    background: url("../../assets/mobileImages/572c4a6f-d136-4236-998f-35df8838e426.png")
      no-repeat center;
    background-size: cover;
    z-index: 161;
  }
  .frame-69_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    z-index: 162;
  }
  .market-place_mob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    width: 215px;
    height: 80px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    z-index: 163;
  }
  .frame-6a_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 48px;
    padding: 8px 8px 8px 8px;
    background: #ffffff;
    z-index: 164;
    border-radius: 100px;
    cursor: pointer;
  }
  .line-arrow-right-6b_mob {
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 32px;
    z-index: 165;
    overflow: hidden;
  }
  .icon-6c_mob {
    position: relative;
    width: 21.333px;
    height: 21.333px;
    margin: 5.334px 0 0 5.333px;
    background: url("../../assets/mobileImages/f44f1df4-7b29-4314-9f98-76b7131117a8.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 166;
  }
  .mobile-white_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 576.615px;
    z-index: 167;
    width: 100%;
    left: 20px;
  }
  .flex-row-be_mob {
    position: relative;
    width: 277.317px;
    height: 23.833px;
    margin: 8.457px 0 0 9.226px;
    z-index: 179;
    overflow: visible auto;
  }
  .top-speaker-6d_mob {
    position: relative;
    width: 87.418px;
    height: 19.989px;
    margin: 0 0 0 94.564px;
    background: url("../../assets/mobileImages/3de9c44a-a1eb-408f-91d0-c3a82894d55d.png")
      no-repeat center;
    background-size: cover;
    z-index: 179;
  }
  .status-bar_mob {
    position: absolute;
    height: 23.833px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 169;
  }
  .bgr_mob {
    position: absolute;
    width: 100%;
    height: 23.833px;
    top: 0;
    left: 0;
    background: #efefef;
    z-index: 170;
    border-radius: 20.758px 20.758px 0 0;
  }
  .time_mob {
    position: absolute;
    width: 44.592px;
    height: 18.452px;
    top: 50%;
    left: 15.376px;
    transform: translate(0, -56.25%);
    z-index: 177;
  }
  .time-6e_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    width: 96.43%;
    height: 78.26%;
    top: 26.08%;
    left: 0.6%;
    color: #717171;
    font-family: Open Sans, var(--default-font-family);
    font-size: 11.532307624816895px;
    font-weight: 700;
    line-height: 14.44px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 0.18px;
    z-index: 178;
  }
  .connections_mob {
    position: absolute;
    width: 53.817px;
    height: 13.07px;
    top: 50%;
    right: 22.838px;
    transform: translate(0, -38.24%);
    z-index: 171;
  }
  .wifi_mob {
    position: absolute;
    width: 30.88%;
    height: 93.75%;
    top: 6.25%;
    left: 29.41%;
    z-index: 173;
  }
  .wifi-6f_mob {
    position: relative;
    width: 12.135px;
    height: 8.985px;
    margin: 1.09px 0 0 2.111px;
    background: url("../../assets/mobileImages/53b7b41d-58ae-49ee-b1a9-ec11a80bba75.png")
      no-repeat center;
    background-size: cover;
    z-index: 174;
  }
  .battery_mob {
    position: absolute;
    width: 36.76%;
    height: 75%;
    top: 12.5%;
    left: 63.23%;
    background: url("../../assets/mobileImages/557fcee2-a44b-4807-b18b-a665d59ff2e9.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 172;
  }
  .cellular-connection_mob {
    position: absolute;
    width: 26.47%;
    height: 75%;
    top: 12.5%;
    left: 0;
    z-index: 175;
  }
  .cellular-connection-70_mob {
    position: relative;
    width: 13.454px;
    height: 8.714px;
    margin: 0.54px 0 0 0.53px;
    background: url("../../assets/mobileImages/b6a5c94a-d0c3-4f6d-995c-0e41b12b0e0b.png")
      no-repeat center;
    background-size: cover;
    z-index: 176;
  }
  .flex-row-ed_mob {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -0.38px;
    left: 0;
    z-index: 180;
    width: 300px;
  }
  .insert-your-screen-here_mob {
    position: relative;
    width: 281.935px;
    height: 533.946px;
    margin: 32.29px 0 0 11.532px;
    background: #efefef;
    z-index: 168;
    overflow: hidden;
    border-radius: 0 0 16.145px 16.145px;
  }
  .device-71_mob {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 1px;
    border: 11.532px solid #ffffff;
    z-index: 180;
    border-radius: 43.823px;
  }
  .why-72_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    min-width: 0;
    z-index: 181;
    overflow: hidden;
  }
  .choose-frank_mob {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    /* width: 375px; */
    height: 80px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-overflow: initial;
    z-index: 182;
    overflow: hidden;
  }
  .arguments-73_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    z-index: 183;
  }
  .arguments-74_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    z-index: 184;
  }
  .frame-75_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 134px;
    z-index: 185;
    overflow: hidden;
  }
  .frame-76_mob {
    position: relative;
    display: flex;
    width: 189px;
    height: 134.822px;
    margin: -1px 0 0 120px;
    background: url("../../assets/mobileImages/c3526cd61ea89a24b2e9ed75bc5ce5f5607b0118.png")
      no-repeat center;
    background-size: cover;
    z-index: 186;
  }
  .frame-77_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 187;
    text-align: center;
    width: 100%;
  }
  .reliability-security_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 188;
  }
  .delivery-insurance_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    /* width: 375px; */
    height: 40px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 189;
  }
  .arguments-78_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    z-index: 190;
  }
  .frame-79_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 134px;
    z-index: 191;
    overflow: hidden;
  }
  .frame-7a_mob {
    position: relative;
    width: 164px;
    height: 135px;
    margin: 0 0 0 126px;
    background: url("../../assets/mobileImages/f96a9bd38166194738de1fd6b8ef88838f0b0204.png")
      no-repeat center;
    background-size: cover;
    z-index: 192;
  }
  .frame-7b_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 193;
  }
  .rates-flexibility_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 194;
  }
  .competitive-pricing_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    /* width: 375px; */
    height: 40px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 195;
  }
  .arguments-7c_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    z-index: 196;
  }
  .frame-7d_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 134px;
    z-index: 197;
    overflow: hidden;
  }
  .frame-7e_mob {
    position: relative;
    width: 128.5px;
    height: 136.631px;
    margin: -1px 0 0 132px;
    background: url("../../assets/mobileImages/13f08d5eb95db9113c2bda922dc604ba1990ed7a.png")
      no-repeat center;
    background-size: cover;
    z-index: 198;
  }
  .frame-7f_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 199;
  }
  .tracking-customer-service_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #2d26e6;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 200;
  }
  .real-time-support_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    z-index: 201;
  }
  .testimonials-press_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    z-index: 202;
  }
  .products-wrapper-80_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    padding: 60px 16px 60px 16px;
    background: #f1cbb6;
    z-index: 203;
    overflow: hidden;
  }
  .vector-81_mob {
    flex-shrink: 0;
    position: absolute;
    width: 848.113px;
    height: 650px;
    top: 137px;
    left: -261.989px;
    background: url("../../assets/mobileImages/bcad83da-17f5-4db7-b740-65c774541d59.png")
      no-repeat center;
    background-size: cover;
    z-index: 204;
  }
  .menu-82_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 205;
  }
  .marketplace-recommendations_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 343px;
    height: 80px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    z-index: 206;
  }
  .arguments-83_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 207;
  }
  .testimonials_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 310px;
    padding: 24px 24px 24px 24px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 208;
    overflow: hidden;
    border-radius: 24px;
  }
  .text_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    min-width: 0;
    z-index: 209;
  }
  .jacques-c_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 32px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    white-space: nowrap;
    z-index: 210;
  }
  .customer-testimonial_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 262px;
    height: 60px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 211;
  }
  .stars_mob {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 172px;
    z-index: 212;
  }
  .line-star_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 213;
    overflow: hidden;
  }
  .icon-84_mob {
    position: relative;
    width: 23.394px;
    height: 22.368px;
    margin: 2.368px 0 0 2.303px;
    background: url("../../assets/mobileImages/331c79d5-4c6c-462e-b1be-d59ac3e8f9b4.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 214;
  }
  .line-star-85_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 215;
    overflow: hidden;
  }
  .icon-86_mob {
    position: relative;
    width: 23.593px;
    height: 22.568px;
    margin: 2.269px 0 0 2.204px;
    background: url("../../assets/mobileImages/77c5aa1e-a737-4527-9eb6-7635a0416848.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 216;
  }
  .line-star-87_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 217;
    overflow: hidden;
  }
  .icon-88_mob {
    position: relative;
    width: 23.593px;
    height: 22.568px;
    margin: 2.269px 0 0 2.204px;
    background: url("../../assets/mobileImages/d39d986f-7860-4fe4-b4a4-5eef06d42e3d.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 218;
  }
  .line-star-89_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 219;
    overflow: hidden;
  }
  .icon-8a_mob {
    position: relative;
    width: 23.593px;
    height: 22.568px;
    margin: 2.269px 0 0 2.204px;
    background: url("../../assets/mobileImages/69ce9a25-d252-48f4-aaf4-ba418935c707.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 220;
  }
  .line-star-8b_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 221;
    overflow: hidden;
  }
  .icon-8c_mob {
    position: relative;
    width: 23.593px;
    height: 22.568px;
    margin: 2.269px 0 0 2.204px;
    background: url("../../assets/mobileImages/df3a13a1-0a55-49a3-89f7-5c438f42c482.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 222;
  }
  .testimonials-8d_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 310px;
    padding: 24px 24px 24px 24px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 223;
    overflow: hidden;
    border-radius: 24px;
  }
  .text-8e_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    min-width: 0;
    z-index: 224;
  }
  .jacques-c-8f_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 32px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    white-space: nowrap;
    z-index: 225;
  }
  .customer-testimonial-90_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 262px;
    height: 60px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 226;
  }
  .stars-91_mob {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 172px;
    z-index: 227;
  }
  .line-star-92_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 228;
    overflow: hidden;
  }
  .icon-93_mob {
    position: relative;
    width: 6.697px;
    height: 22.368px;
    margin: 2.368px 0 0 2.303px;
    background: url("../../assets/mobileImages/eafce7e3-a9c0-4e6a-a278-97144a19f870.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 229;
  }
  .line-star-94_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 230;
    overflow: hidden;
  }
  .icon-95_mob {
    position: relative;
    width: 21.994px;
    height: 20.968px;
    margin: 3.068px 0 0 3.003px;
    background: url("../../assets/mobileImages/309b7d44-84f8-4b95-bd80-63e30876ca57.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 231;
  }
  .line-star-96_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 232;
    overflow: hidden;
  }
  .icon-97_mob {
    position: relative;
    width: 21.994px;
    height: 20.968px;
    margin: 3.068px 0 0 3.003px;
    background: url("../../assets/mobileImages/0bafffc6-3f5c-4cb6-9dbc-2510ed857676.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 233;
  }
  .line-star-98_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 234;
    overflow: hidden;
  }
  .icon-99_mob {
    position: relative;
    width: 21.994px;
    height: 20.968px;
    margin: 3.068px 0 0 3.003px;
    background: url("../../assets/mobileImages/8b228e4e-f9bf-4ecb-b275-63d304097531.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 235;
  }
  .line-star-9a_mob {
    flex-shrink: 0;
    position: relative;
    width: 28px;
    height: 28px;
    z-index: 236;
    overflow: hidden;
  }
  .icon-9b_mob {
    position: relative;
    width: 21.994px;
    height: 20.968px;
    margin: 3.068px 0 0 3.003px;
    background: url("../../assets/mobileImages/39eba2dc-7245-4d85-b621-c670ba620b0c.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 237;
  }
  .button-9c_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 222px;
    padding: 10px 24px 10px 24px;
    background: #ef6a31;
    z-index: 238;
    border-radius: 12px;
  }
  .voir-tous-les-produits-9d_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 239;
  }
  .news_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 48px;
    position: relative;
    padding: 40px 160px 40px 160px;
    background: #fce6dd;
    z-index: 240;
    overflow: hidden;
  }
  .image-9e_mob {
    flex-shrink: 0;
    position: relative;
    width: 37.77px;
    height: 32px;
    background: url("../../assets/mobileImages/f5d85525f27a1465145396b743f9144672c17306.png")
      no-repeat center;
    background-size: cover;
    z-index: 241;
  }
  .image-9f_mob {
    flex-shrink: 0;
    position: relative;
    width: 87.082px;
    height: 32px;
    background: url("../../assets/mobileImages/a0462ff54ea8811aa4a8ac34ef6374f545eebca0.png")
      no-repeat center;
    background-size: cover;
    z-index: 242;
  }
  .image-a0_mob {
    flex-shrink: 0;
    position: relative;
    width: 131.333px;
    height: 32px;
    background: url("../../assets/mobileImages/34fe0f36-e495-4a9b-9400-b2c366794ee1.png")
      no-repeat center;
    background-size: cover;
    z-index: 243;
  }
  .image-a1_mob {
    flex-shrink: 0;
    position: relative;
    width: 102.437px;
    height: 32px;
    background: url("../../assets/mobileImages/b4b5c3de1e65b8bed50f5c3da38a44fd4245365b.png")
      no-repeat center;
    background-size: cover;
    z-index: 244;
  }
  .image-a2_mob {
    flex-shrink: 0;
    position: relative;
    width: 120.372px;
    height: 32px;
    background: url("../../assets/mobileImages/c4406b25-597c-4b30-84ce-0a43cc8c0ab3.png")
      no-repeat center;
    background-size: cover;
    z-index: 245;
  }
  .why-a3_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    min-width: 0;
    padding: 0 16px 0 16px;
    z-index: 246;
    overflow: hidden;
  }
  .menu-a4_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 247;
  }
  .achetez-vos-produits-a5_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 40px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    white-space: nowrap;
    z-index: 248;
  }
  .soyez-livres-a6_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 340px;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    z-index: 249;
  }
  .frame-a7_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 343px;
    z-index: 250;
  }
  .actu_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 343px;
    padding: 0 0 16px 0;
    background: #ffffff;
    z-index: 251;
    overflow: hidden;
    border-radius: 24px;
  }
  .img-wrapper-blue_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 100px;
    background: #2d26e6;
    z-index: 252;
    overflow: hidden;
    border-radius: 24px 24px 0 0;
  }
  .text-a8_mob {
    position: relative;
    width: 283px;
    height: 178px;
    margin: 16px 0 0 30px;
    background: url("../../assets/mobileImages/4178bd38b4f034f0bdb1e2b59e086db0061d5dae.png")
      no-repeat center;
    background-size: cover;
    z-index: 253;
  }
  .title-a9_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    padding: 0 16px 0 16px;
    z-index: 254;
  }
  .du-nouveau-chez-frank_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 255;
  }
  .il-y-a-minutes-aa_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 256;
  }
  .lorem-ipsum-dolor_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 257;
  }
  .voir-larticle_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 311px;
    height: 60px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 258;
  }
  .actu-ab_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    z-index: 259;
  }
  .img-wrapper-orange_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 343px;
    padding: 0 0 16px 0;
    background: #ffffff;
    z-index: 260;
    overflow: hidden;
    border-radius: 24px;
  }
  .text-ac_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 100px;
    background: #ef6a31;
    z-index: 261;
    overflow: hidden;
    border-radius: 24px 24px 0 0;
  }
  .title-ad_mob {
    position: relative;
    width: 294px;
    height: 147px;
    margin: 24px 0 0 30px;
    background: url("../../assets/mobileImages/2d3a1a6534b323ca114024b7648be946f62cf1ae.png")
      no-repeat center;
    background-size: cover;
    z-index: 262;
  }
  .du-nouveau-chez-frank-ae_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    padding: 0 16px 0 16px;
    z-index: 263;
  }
  .il-y-a-minutes-af_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 264;
  }
  .lorem-ipsum-dolor-b0_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 265;
  }
  .voir-larticle-b1_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 266;
  }
  .actu-b2_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 311px;
    height: 60px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 267;
  }
  .img-wrapper-blue-b3_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    z-index: 268;
  }
  .text-b4_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 343px;
    padding: 0 0 16px 0;
    background: #ffffff;
    z-index: 269;
    overflow: hidden;
    border-radius: 24px;
  }
  .title-b5_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    height: 100px;
    background: #2d26e6;
    z-index: 270;
    overflow: hidden;
    border-radius: 24px 24px 0 0;
  }
  .du-nouveau-chez-frank-b6_mob {
    position: relative;
    width: 219px;
    height: 249px;
    margin: -45px 0 0 62px;
    background: url("../../assets/mobileImages/8f16403f4d897135c9b711b0128e82eded073f5f.png")
      no-repeat center;
    background-size: cover;
    z-index: 271;
  }
  .il-y-a-minutes-b7_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    padding: 0 16px 0 16px;
    z-index: 272;
  }
  .lorem-ipsum-dolor-b8_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 4px;
    position: relative;
    z-index: 273;
  }
  .voir-larticle-b9_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 274;
  }
  .button-ba_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #4b4a61;
    font-family: Montserrat, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 275;
  }
  .voir-tous-les-produits-bb_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 311px;
    height: 60px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    z-index: 276;
  }
  .bottom_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #ef6a31;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    z-index: 277;
  }
  .faq_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 184px;
    padding: 10px 24px 10px 24px;
    background: #2d26e6;
    z-index: 278;
    border-radius: 12px;
  }
  .des-questions_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 279;
  }
  .frame-bc_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    min-width: 0;
    z-index: 280;
  }
  .frame-bd_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    padding: 32px 0 32px 0;
    z-index: 281;
  }
  .line_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 40px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    z-index: 282;
  }
  .span-what-is-frank_mob{
  width: 100% !important;
  }
    .faq-be_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 48px;
    position: relative;
    padding: 0 24px 0 24px;
    z-index: 283;
  }
  .quest-ce-que-frank_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    z-index: 284;
  }
  .line-chevron-down_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: url("../../assets/mobileImages/9b742c55-76f7-4f59-b96b-a2eb1a9a97eb.png")
      no-repeat center;
    background-size: cover;
    z-index: 285;
  }
  .icon-bf_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    z-index: 286;
  }
  .line-c0_mob {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 287;
  }
  .faq-c1_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 288;
    overflow: hidden;
  }
  .quest-ce-que-frank-c2_mob {
    position: relative;
    width: 14px;
    height: 8px;
    margin: 8px 0 0 5px;
    background: url("../../assets/mobileImages/704eeb73-6dce-4683-858d-59047f61325b.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 289;
  }
  .comment-fonctionne-la-livraison_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: url("../../assets/mobileImages/67d12efc-7901-4a69-a17b-4b4c2ae4a1d3.png")
      no-repeat center;
    background-size: cover;
    z-index: 290;
  }
  .line-chevron-down-c3_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    z-index: 291;
  }
  .icon-c4_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    width: 263px;
    height: 56px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    z-index: 292;
  }
  .line-c5_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 293;
    overflow: hidden;
  }
  .faq-c6_mob {
    position: relative;
    width: 14px;
    height: 8px;
    margin: 8px 0 0 5px;
    background: url("../../assets/mobileImages/a9aff137-b04d-4459-8c08-079dcb80a15f.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 294;
  }
  .quest-ce-que-frank-c7_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: url("../../assets/mobileImages/c1d9d118-2d85-4f1e-b98e-26f431f68690.png")
      no-repeat center;
    background-size: cover;
    z-index: 295;
  }
  .que-se-passe-t-il_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    z-index: 296;
  }
  .line-chevron-down-c8_mob {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    position: relative;
    width: 263px;
    height: 56px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    z-index: 297;
  }
  .icon-c9_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 298;
    overflow: hidden;
  }
  .line-ca_mob {
    position: relative;
    width: 14px;
    height: 8px;
    margin: 8px 0 0 5px;
    background: url("../../assets/mobileImages/2754da8b-7765-4460-835f-4924cf0ee406.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 299;
  }
  .faq-cb_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: url("../../assets/mobileImages/844b3b62-422c-4667-bd40-c02a76c4c25e.png")
      no-repeat center;
    background-size: cover;
    z-index: 300;
  }
  .quest-ce-que-frank-cc_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    z-index: 301;
  }
  .y-a-t-il-une-limite_mob {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 28px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 302;
  }
  .line-chevron-down-cd_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 303;
    overflow: hidden;
  }
  .icon-ce_mob {
    position: relative;
    width: 14px;
    height: 8px;
    margin: 8px 0 0 5px;
    background: url("../../assets/mobileImages/8e978816-3a5f-446d-9f93-a2b731318630.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 304;
  }
  .line-cf_mob {
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    height: 1px;
    background: url("../../assets/mobileImages/d5cb60e2-e2c6-45af-ab46-f6fe70947080.png")
      no-repeat center;
    background-size: cover;
    z-index: 305;
  }
  .seo_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 0 16px 0 16px;
    z-index: 306;
  }
  .background_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 32px;
    position: relative;
    padding: 24px 16px 24px 16px;
    background: #f1cbb6;
    z-index: 307;
    overflow: hidden;
    border-radius: 24px;
  }
  .vector-d0_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2531.475px;
    height: 2038.318px;
    top: -169px;
    left: -863.001px;
    background: url("../../assets/mobileImages/327b89c7-d9d5-424b-897b-913bffdae9a1.png")
      no-repeat center;
    background-size: cover;
    z-index: 308;
  }
  .menu-d1_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 170px;
    z-index: 309;
  }
  .achetez-vos-produits-d2_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 170px;
    height: 80px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    text-overflow: initial;
    z-index: 310;
    overflow: hidden;
  }
  .tout-savoir_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    z-index: 311;
  }
  .solid-heart-circle_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 312;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-d3_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 313;
  }
  .redirection_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/e41a2c77-90f0-4245-8722-736c8e348b14.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 314;
  }
  .text-53_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 315;
  }
  .frame-d4_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 316;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-d5_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 317;
  }
  .solid-d6_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/98d6b1dd-8ace-4e21-8bc5-da6484d02afd.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 318;
  }
  .redirection-d7_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 319;
  }
  .frame-d8_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 320;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-d9_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 321;
  }
  .solid-da_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/8d2abf27-6088-4f5d-afc0-1e90a1b50eaa.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 322;
  }
  .redirection-db_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 323;
  }
  .frame-dc_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 324;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-dd_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 325;
  }
  .solid-de_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/29b119b5-e3e7-417c-9349-567a3a4e270d.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 326;
  }
  .redirection-df_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 327;
  }
  .frame-e0_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 328;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-e1_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 329;
  }
  .solid-e2_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/6df8f6bd-2d7a-48b5-8cca-d0492368bb6d.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 330;
  }
  .redirection-e3_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 331;
  }
  .frame-e4_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 332;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-e5_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 333;
  }
  .solid-e6_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/e1d40f84-d041-4f2d-b1e8-b66ab8689255.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 334;
  }
  .redirection-e7_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 335;
  }
  .frame-e8_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 336;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-e9_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 337;
  }
  .solid-ea_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/2f97e783-8eb8-45d0-8b34-c7c60f8aa665.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 338;
  }
  .redirection-eb_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 339;
  }
  .frame-ec_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 340;
    overflow: hidden;
    border-radius: 16px;
  }
  .solid-heart-circle-ed_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 341;
  }
  .solid-ee_mob {
    position: relative;
    width: 22px;
    height: 22px;
    margin: 1px 0 0 1px;
    background: url("../../assets/mobileImages/cabfea7b-a949-461f-9ebb-ddf931279f06.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 342;
  }
  .redirection-ef_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 343;
  }
  .frame-f0_mob {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 232px;
    z-index: 344;
  }
  .voir-toutes-les-categories_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #281208;
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 345;
  }
  .line-chevron-down-f1_mob {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 346;
    overflow: hidden;
  }
  .icon-f2_mob {
    position: relative;
    width: 14px;
    height: 8px;
    margin: 8px 0 0 5px;
    background: url("../../assets/mobileImages/d47cfeeb-b06f-4dd1-91ff-43bf394f41b3.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 347;
  }
  .get-the-app-wrapper_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 0 16px 0 16px;
    z-index: 348;
  }
  .background-f3_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    background: #2d26e6;
    z-index: 349;
    overflow: hidden;
    border-radius: 24px;
  }
  .vector-f4_mob {
    flex-shrink: 0;
    position: absolute;
    width: 2459.012px;
    height: 1858.661px;
    top: 76px;
    left: -725.001px;
    background: url("../../assets/mobileImages/45699bf5-4bcf-4e61-9267-a76d57d6745a.png")
      no-repeat center;
    background-size: cover;
    z-index: 350;
  }
  .container_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    padding: 24px 24px 24px 24px;
    z-index: 351;
  }
  .frame-f5_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 1px;
    position: relative;
    width: 215px;
    z-index: 352;
  }
  .download-app_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 22.5px;
    color: rgba(255, 255, 255, 0.7);
    font-family: Roboto, var(--default-font-family);
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    letter-spacing: 2px;
    z-index: 353;
  }
 
  .frame-f6_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 32px;
    left: 263px;
    padding: 8px 8px 8px 8px;
    background: #ffffff;
    z-index: 355;
    border-radius: 100px;
  }
  .line-arrow-right-f7_mob {
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 32px;
    z-index: 356;
    overflow: hidden;
  }
  .icon-f8_mob {
    position: relative;
    width: 21.333px;
    height: 21.333px;
    margin: 5.334px 0 0 5.333px;
    background: url("../../assets/mobileImages/5af8fdf7-9de5-4bd2-a883-eeef85ce5169.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 357;
  }
  .download-customer-app-and-transporter-app_mob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 295px;
    height: 40px;
    color: #ffffff;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 358;
  }
  .frame-f9_mob {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 292.404px;
    z-index: 359;
  }
  .link_mob {
    flex-shrink: 0;
    position: relative;
    width: 92.135px;
    height: 27.292px;
    z-index: 360;
  }
 
  
  .link-fb_mob {
    flex-shrink: 0;
    position: relative;
    width: 92.135px;
    height: 27.292px;
    z-index: 364;
  }
  .app-store-badge-svg_mob {
    position: relative;
    width: 92.135px;
    height: 27.292px;
    margin: 0 0 0 0px;
    z-index: 365;
    overflow: hidden;
  }
  .app-store-badge-svg-fill_mob {
    position: relative;
    width: 92.135px;
    height: 27.292px;
    margin: 0 0 0 0px;
    z-index: 366;
    overflow: hidden;
  }
  .app-store-badge-svg-fc_mob {
    position: relative;
    width: 92.111px;
    height: 27.292px;
    margin: 0 0 0 0.01px;
    z-index: 367;
    overflow: hidden;
  }
  .group_mob {
    position: relative;
    width: 92.111px;
    height: 27.292px;
    margin: 0 0 0 0px;
    z-index: 368;
  }
  .vector-fd_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/mobileImages/78f3e70a-ed13-40b7-86ee-04321074ff12.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 369;
  }
  .vector-fe_mob {
    position: absolute;
    width: 98.62%;
    height: 95.52%;
    top: 2.23%;
    left: 0.66%;
    background: url("../../assets/mobileImages/fd3d195f-7d8c-4265-8b1e-b3f47dc3ddcf.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 370;
  }
  .group-ff_mob {
    position: absolute;
    width: 15.37%;
    height: 63.78%;
    top: 15.54%;
    left: 9.47%;
    z-index: 371;
  }
  .group-100_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 372;
  }
  .vector-101_mob {
    position: relative;
    width: 3.517px;
    height: 4.02px;
    margin: 0 0 0 7.037px;
    background: url("../../assets/mobileImages/a2ca2157-ed32-4477-8c00-631157056514.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 374;
  }
  .vector-102_mob {
    position: relative;
    width: 14.162px;
    height: 13.208px;
    margin: 0.18px 0 0 0;
    background: url("../../assets/mobileImages/b2033b0e-e6ea-41ff-a767-81881ec96b4e.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 373;
  }
  .group-103_mob {
    position: absolute;
    width: 57.43%;
    height: 17.46%;
    top: 16.39%;
    left: 32.35%;
    z-index: 384;
  }
  .group-104_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 385;
  }
  .vector-105_mob {
    position: relative;
    width: 0.71px;
    height: 4.692px;
    margin: 0 0 0 17.988px;
    background: url("../../assets/mobileImages/92a07560-4c93-4d47-8aa7-96a871cdd01a.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 390;
  }
  .flex-row_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 21.861px;
    height: 3.355px;
    margin: -3.282px 0 0 4.514px;
    z-index: 392;
  }
  .vector-106_mob {
    flex-shrink: 0;
    position: relative;
    width: 3.15px;
    height: 3.355px;
    background: url("../../assets/mobileImages/c73b05c4-0c8b-4f8b-a6ae-9d4ee5ad69aa.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 387;
  }
  .regroup_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    position: relative;
    width: 6.611px;
    height: 3.355px;
    z-index: 392;
  }
  .vector-107_mob {
    flex-shrink: 0;
    position: relative;
    width: 3.15px;
    height: 3.355px;
    background: url("../../assets/mobileImages/56216247-0be4-4923-b3db-b490ab55a991.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 391;
  }
  .vector-108_mob {
    flex-shrink: 0;
    position: relative;
    width: 2.641px;
    height: 3.355px;
    background: url("../../assets/mobileImages/f692f217-6e8a-4a2a-816c-506e71bc05db.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 392;
  }
  .flex-row-dea_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 398;
  }
  .vector-109_mob {
    position: absolute;
    width: 5.8%;
    height: 100%;
    top: 0;
    left: 51.73%;
    background: url("../../assets/mobileImages/764cd6ce-9200-432a-9724-cabc77b38f0f.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 393;
  }
  .regroup-10a_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 10.86%;
    height: 100%;
    top: 0;
    left: 81.76%;
    z-index: 397;
  }
  .vector-10b_mob {
    flex-shrink: 0;
    position: relative;
    width: 1.959px;
    height: 4.084px;
    background: url("../../assets/mobileImages/05d0c49b-f8b0-4ad0-9599-06f5e37f4f3c.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 396;
  }
  .vector-10c_mob {
    flex-shrink: 0;
    position: relative;
    width: 2.793px;
    height: 4.692px;
    background: url("../../assets/mobileImages/48d4414b-804a-4424-a2fe-a0e633efd4fe.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 397;
  }
  .vector-10d_mob {
    position: absolute;
    width: 6.93%;
    height: 95.15%;
    top: 4.14%;
    left: 0;
    background: url("../../assets/mobileImages/8e8b7568-38c4-44ba-b203-edf235759bf2.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 386;
  }
  .vector-10e_mob {
    position: absolute;
    width: 5.41%;
    height: 70.26%;
    top: 29.47%;
    left: 94.59%;
    background: url("../../assets/mobileImages/b265c2c2-1a60-42ac-a2e1-dcc08e363ccb.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 398;
  }
  .vector-10f_mob {
    position: absolute;
    width: 5.33%;
    height: 68.91%;
    top: 29.55%;
    left: 26.3%;
    background: url("../../assets/mobileImages/2c1338eb-e91f-4d19-ab68-8d3f4c9a9bbd.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 389;
  }
  .vector-110_mob {
    position: absolute;
    width: 5.33%;
    height: 68.91%;
    top: 29.55%;
    left: 71.04%;
    background: url("../../assets/mobileImages/e958c083-3b44-4ddf-aa6a-0d822637b2b0.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 395;
  }
  .vector-111_mob {
    position: absolute;
    width: 5.95%;
    height: 70.4%;
    top: 29.59%;
    left: 63.14%;
    background: url("../../assets/mobileImages/30fe55ea-8edf-4d80-9b76-620e0a6fbb35.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 394;
  }
  .vector-112_mob {
    position: absolute;
    width: 8.81%;
    height: 67.5%;
    top: 30.99%;
    left: 15.81%;
    background: url("../../assets/mobileImages/26dfa543-118a-4ab2-a2bd-788ca60c9715.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 388;
  }
  .group-113_mob {
    position: absolute;
    width: 59.07%;
    height: 43.53%;
    top: 45%;
    left: 31.41%;
    z-index: 375;
  }
  .flex-row-a_mob {
    position: relative;
    width: 54.41px;
    height: 9.348px;
    margin: 0 0 0 0;
    z-index: 383;
  }
  .vector-114_mob {
    position: absolute;
    width: 10.35%;
    height: 100%;
    top: 0;
    left: 46.87%;
    background: url("../../assets/mobileImages/7632b950-9d98-4c67-83da-b23fcc871379.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 379;
  }
  .regroup-115_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 18.39%;
    height: 100%;
    top: 0;
    left: 81.61%;
    z-index: 383;
  }
  .vector-116_mob {
    flex-shrink: 0;
    position: relative;
    width: 3.503px;
    height: 6.682px;
    background: url("../../assets/mobileImages/a74f5d04-dff9-4b3b-b4c3-2b5dfc3e811a.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 382;
  }
  .vector-117_mob {
    flex-shrink: 0;
    position: relative;
    width: 5.819px;
    height: 6.816px;
    background: url("../../assets/mobileImages/bcb66a11-5053-45a4-9848-ed111eac3aa1.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 383;
  }
  .vector-118_mob {
    position: absolute;
    width: 14.09%;
    height: 97.14%;
    top: 1.42%;
    left: 0;
    background: url("../../assets/mobileImages/d876eb6e-6306-4331-922e-0a0a4c75f65a.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 376;
  }
  .vector-119_mob {
    position: absolute;
    width: 7.33%;
    height: 88.93%;
    top: 11.06%;
    left: 58.73%;
    background: url("../../assets/mobileImages/12eed704-c8fb-4cf5-986c-87cff65c4e18.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 380;
  }
  .flex-row-d_mob {
    position: relative;
    width: 34.3px;
    height: 9.35px;
    margin: -6.816px 0 0 8.877px;
    z-index: 381;
  }
  .vector-11a_mob {
    position: absolute;
    width: 18.7%;
    height: 73.04%;
    top: 0;
    left: 81.3%;
    background: url("../../assets/mobileImages/8d28c2fe-6b0d-4eb8-ad94-c9ae728d40fe.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 381;
  }
  .regroup-11b_mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 40.34%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 378;
  }
  .vector-11c_mob {
    flex-shrink: 0;
    position: relative;
    width: 6.305px;
    height: 9.35px;
    background: url("../../assets/mobileImages/939d7019-16f5-44f6-82b0-49724908edd8.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 377;
  }
  .vector-11d_mob {
    flex-shrink: 0;
    position: relative;
    width: 6.304px;
    height: 9.35px;
    background: url("../../assets/mobileImages/8d5b31d2-2794-4471-b48c-80486c3d3a28.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 378;
  }
  .link-11e_mob {
    flex-shrink: 0;
    position: relative;
    width: 92.135px;
    height: 27.438px;
    z-index: 399;
  }
  .huawei-appgallery-badge_mob {
    position: relative;
    width: 92.135px;
    height: 27.438px;
    margin: 0 0 0 0px;
    z-index: 400;
    overflow: hidden;
  }
  .huawei-appgallery-badge-fill_mob {
    position: relative;
    width: 92.135px;
    height: 27.438px;
    margin: 0 0 0 0px;
    z-index: 401;
    overflow: hidden;
  }
  .huawei-appgallery-badge-11f_mob {
    position: relative;
    width: 92.114px;
    height: 27.438px;
    margin: 0 0 0 0.01px;
    z-index: 402;
    overflow: hidden;
  }
  .group-120_mob {
    position: relative;
    width: 91.466px;
    height: 27.44px;
    margin: 0px 0 0 0.32px;
    background: url("../../assets/mobileImages/939f33e2-97e7-4a6e-987c-1bd02738a106.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 403;
  }
  .frame-121_mob {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 24px 0 0 0;
    z-index: 404;
  }
  .screen-png_mob {
    flex-shrink: 0;
    position: relative;
    width: 300px;
    height: 301.01px;
    background: url("../../assets/mobileImages/3b5b3e6d-5b46-426d-835b-377ed8b99221.png")
      no-repeat center;
    background-size: cover;
    z-index: 405;
    overflow: hidden;
  }
  .frame-122_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    min-width: 0;
    z-index: 406;
  }
  .frame-123_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    height: 208px;
    padding: 0 16px 0 16px;
    z-index: 407;
  }
  .tracking_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 16px 24px 16px 24px;
    background: #ffffff;
    z-index: 408;
    border-radius: 24px;
    box-shadow: 0 -30px 100px 0 rgba(0, 37, 133, 0.03);
  }
  .icon-svg_mob {
    position: relative;
    width: 40px;
    height: 35.789px;
    z-index: 409;
    overflow: hidden;
  }
  .icon-svg-fill_mob {
    position: relative;
    width: 40px;
    height: 35.789px;
    margin: 0px 0 0 0;
    z-index: 410;
    overflow: hidden;
  }
  .icon-svg-124_mob {
    position: absolute;
    width: 40px;
    height: 35.789px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 411;
    overflow: hidden;
  }
  .mask-group_mob {
    position: absolute;
    width: 89.42%;
    height: 99.93%;
    top: 0;
    left: 10.39%;
    z-index: 412;
    -webkit-mask-image: url("../../assets/mobileImages/be613fba-da8a-47f0-87dd-0946715ffc9c.png");
    -webkit-mask-size: cover;
  }
  .group-125_mob {
    position: relative;
    width: 36.074px;
    height: 38.541px;
    margin: -2.775px 0 0 0.93px;
    z-index: 413;
  }
  .vector-126_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/mobileImages/0d1cca62-1ac3-4b47-9a30-8e1ba2368d18.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 414;
  }
  .vector-127_mob {
    position: absolute;
    width: 39.47%;
    height: 44.12%;
    top: 0;
    left: 1.12%;
    background: url("../../assets/mobileImages/93befab4-b8e1-4cda-9f94-cbf4ec985a28.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 415;
  }
  .vector-128_mob {
    position: absolute;
    width: 20.63%;
    height: 19.11%;
    top: 12.8%;
    left: 10.84%;
    background: url("../../assets/mobileImages/f7ab7d4c-08d4-488c-b886-1fab1a010599.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 416;
  }
  .frame-129_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-basis: 0;
    gap: 8px;
    position: relative;
    height: 92px;
    z-index: 417;
  }
  .heading-tracking_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #ee6931;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 418;
  }
  .track-order_mob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    width: 239px;
    height: 40px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 419;
  }
  .tracking-12a_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    z-index: 420;
    border-radius: 24px;
    box-shadow: 0 -30px 100px 0 rgba(0, 37, 133, 0.03);
  }
  .icon-svg-12b_mob {
    position: relative;
    width: 40px;
    height: 31.807px;
    z-index: 421;
    overflow: hidden;
  }
  .icon-svg-fill-12c_mob {
    position: absolute;
    width: 40px;
    height: 31.807px;
    top: 0;
    left: 0;
    z-index: 422;
    overflow: hidden;
  }
  .icon-svg-12d_mob {
    position: absolute;
    width: 40px;
    height: 31.807px;
    top: 50%;
    left: 50%;
    background: url("../../assets/mobileImages/1c1b7341-9c8d-4402-ad4f-9a56842b4906.png")
      no-repeat center;
    background-size: cover;
    transform: translate(-50%, -50%);
    z-index: 423;
    overflow: hidden;
  }
  .frame-12e_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-basis: 0;
    gap: 8px;
    position: relative;
    z-index: 424;
  }
  .heading-support_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #ee6931;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 425;
  }
  .question-inquiry_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 20px;
    color: #4b4a61;
    font-family: Raleway, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 426;
  }
  .phone-number_mob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 174.806px;
    height: 28px;
    color: #080626;
    font-family: Raleway, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    white-space: nowrap;
    z-index: 427;
  }
  .footer_mob {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 40px;
    position: relative;
    width: 100%;
    padding: 100px 24px 24px 24px;
    background: #2d26e6;
    z-index: 0;
    overflow: hidden;
  }
  .shape-png_mob {
    flex-shrink: 0;
    position: absolute;
    width: 341px;
    height: 393px;
    top: 413.14px;
    left: 0;
    background: url("../../assets/mobileImages/6aa38542-39a8-46f8-a5cc-a52e42af7ed3.png")
      no-repeat center;
    background-size: cover;
    z-index: 429;
    overflow: hidden;
  }
  .shape-png-12f_mob {
    flex-shrink: 0;
    position: absolute;
    width: 169px;
    height: 204px;
    top: 50%;
    right: 2px;
    background: url("../../assets/mobileImages/c1bb002b-9df5-4b10-9ac2-afeafe4dc579.png")
      no-repeat center;
    background-size: cover;
    transform: translate(0, -154.32%);
    z-index: 430;
    overflow: hidden;
  }
  .logo_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 100%;
    z-index: 431;
    padding-left: 30px;
  }
  .link-130_mob {
    flex-shrink: 0;
    position: relative;
    width: 129px;
    height: 66.64px;
    z-index: 432;
  }
  .white-logo_mob {
    position: absolute;
    width: 129px;
    height: 66.64px;
    top: 0;
    left: 0;
    z-index: 433;
    overflow: hidden;
  }
  .white-logo-fill_mob {
    position: absolute;
    width: 129px;
    height: 66.64px;
    top: 0;
    left: 0;
    z-index: 434;
    overflow: hidden;
  }
  .white-logo-131_mob {
    position: relative;
    width: 128.98px;
    height: 66.64px;
    margin: 0 0 0 0.01px;
    z-index: 435;
    overflow: hidden;
  }
  .group-132_mob {
    position: relative;
    width: 124.577px;
    height: 66.081px;
    margin: 0 0 0 0;
    z-index: 436;
  }
  .layer_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/mobileImages/bd8e505b-3684-4c73-9b26-9ee63744dee1.png")
      no-repeat center;
    background-size: 100% 100%;
    z-index: 437;
  }
  .list_mob {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    z-index: 438;
  }
  .item-link_mob {
    flex-shrink: 0;
    position: relative;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.17);
    z-index: 439;
    border-radius: 6px;
  }
  .symbol_mob {
    position: absolute;
    width: 10.313px;
    height: 16px;
    top: 6px;
    left: 10px;
    font-family: Font Awesome 5 Brands, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    background: url("../../assets/mobileImages/b36aa8d8-c89e-4f24-ae87-1020a8395fb9.png")
      no-repeat center;
    background-size: cover;
    z-index: 440;
  }
  .item-link-133_mob {
    flex-shrink: 0;
    position: relative;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.17);
    z-index: 441;
    border-radius: 6px;
  }
  .symbol-134_mob {
    position: absolute;
    width: 16.388px;
    height: 16px;
    top: 6px;
    left: 7px;
    font-family: Font Awesome 5 Brands, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    background: url("../../assets/mobileImages/5b8b29be-0287-46ac-863b-20cd1bddb588.png")
      no-repeat center;
    background-size: cover;
    z-index: 442;
  }
  .item-link-135_mob {
    flex-shrink: 0;
    position: relative;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.17);
    z-index: 443;
    border-radius: 6px;
  }
  .symbol-136_mob {
    position: absolute;
    width: 14.363px;
    height: 16px;
    top: 6px;
    left: 8px;
    font-family: Font Awesome 5 Brands, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    background: url("../../assets/mobileImages/9c8a3800-d208-4a4e-9b81-8912b66dfb30.png")
      no-repeat center;
    background-size: cover;
    z-index: 444;
  }
  .list-137_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 100%;
    z-index: 445;
    padding-left: 30px;
  }
  .customer-help_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 446;
  }
  .support-center_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 447;
  }
  .my-order-status_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 448;
  }
  .home_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 449;
  }
  .list-138_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    padding-left: 30px;
    width: 100%;
    z-index: 450;
  }
  .rider_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 451;
  }
  .air-rider_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 452;
  }
  .fleet-manager_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 453;
  }
  .sub-rider_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 454;
  }
  .list-139_mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    width: 100%;
    z-index: 455;
    padding-left: 30px;
  }
  .e-merchant-store_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 456;
  }
  .space-store_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 457;
  }
  .bc-store_mob {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 458;
  }
  .frank_mob {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 19px;
    color: rgba(255, 255, 255, 0.85);
    font-family: Raleway, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 18.784px;
    text-align: center;
    white-space: nowrap;
    z-index: 459;
    cursor: pointer;
  }
 
  
  