.font_resp {
    font-size: 1rem;
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .login_area {
      display: none;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .login_area {
      display: none;
    }
    .hide_login_drawer {
      display: flex;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .login_area {
      display: none;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .login_area {
      display: flex;
      gap: 8;
    }
    .hide_login_drawer {
      display: none;
    }
  }