:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}
body {
  background-color: #fff;
}
.main-container {
  overflow: hidden;
  width: 100%;
}

.main-container,
.main-container * {
  box-sizing: border-box;
  width: 100%;
}
.main-container .no-inherit {
  box-sizing: content-box; /* Revert to the default box-sizing */
  width: auto; /* Or any other width */
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  /* border-radius: 24px; */
}
.header {
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  padding: 10px 20px;
  background: #f9c9b3;
}

.frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  padding: 13px;
  background: #fff;
  z-index: 1;
  border-radius: 100px;
  backdrop-filter: blur(25px);
}

.left {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.items {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 870px;
  z-index: 4;
}

.menu-component {
  z-index: 5;
  position: relative;
}

.particulier {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  color: #080626;
  font-family: "Raleway-Semi-Bold";
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
  display: inline-block;
}
.particulier-marketplace {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  color: #080626;
  font-family: "Raleway-Semi-Bold-Italic";
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
  display: inline-block;
}
.particulier:hover {
  color: #3026f1;
}
.particulier-marketplace:hover {
  color: #ee6931;
}

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 11111;
  border-radius: 4px;
  padding: 8px 0;
  border-radius: 20px;
  padding: 20px 10px 20px 10px;
}

.menu-component:hover .dropdown {
  display: block;
  z-index: 11111;
}

.dropdown-item {
  padding: 15px 16px;
  cursor: pointer;
  white-space: nowrap;
  font-family: "Raleway-Variable";
  font-weight: 500;
  z-index: 11111;
}

.dropdown-item:hover {
  background-color: #fce6dd;
  border-radius: 10px;
  color: #ee6931;
  z-index: 11111;
}

.frank-logo {
  flex-shrink: 0;
  position: relative;
  width: 60.328px;
  height: 32px;
  background-size: cover;
  z-index: 3;
}

.line-chevron-down {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 7;
  overflow: hidden;
}

.menu-component-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 126px;
  z-index: 9;
}
.transporteur {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.transporteur:hover {
  color: #3026f1;
}
.line-chevron-down-2 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 11;
  overflow: hidden;
}

.menu-component-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 152px;
  z-index: 13;
}
.demenagement {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 14;
}
.demenagement:hover {
  color: #3026f1;
}
.line-chevron-down-5 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 17px;
  z-index: 15;
  overflow: hidden;
}
.auth-background {
  background: url("../../assets/images/FrankIcons/bg-franklogin.jpg") no-repeat;
  background-size: contain;
}

.menu-component-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 99px;
  z-index: 17;
}
.market-place {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 18;
}
.market-place:hover {
  color: #3026f1;
}
.menu-component-8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 139px;
  z-index: 19;
}
.stockage-colis {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.stockage-colis:hover {
  color: #3026f1;
}
.line-chevron-down-9 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 21;
  overflow: hidden;
}

.blog {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 18.784px;
  text-align: left;
  white-space: nowrap;
  z-index: 23;
}
.blog:hover {
  color: #3026f1;
}
.right {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  width: 300px;
  z-index: 24;
  justify-content: center;
}
.tracker {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 24px;
  z-index: 25;
}

.line-mail {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 26;
  overflow: hidden;
}
.fa-search {
  color: red;
  height: 100px;
}

.icon-b:hover {
  color: "red";
}
.tracker-c {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 24px;
  z-index: 28;
}
.search {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 29;
}

.compte {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 24px;
  z-index: 31;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 80px;
  position: relative;
  min-width: 0;
  padding: 0 0 0px 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
 
.frame-f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  min-height: 0;
  padding: 46px 48px 48px 48px;
  background: #fff;
  z-index: 36;
  border-radius: 48px;
  gap: 45px;
  height: 100%;
}

.envoyez-vos-colis {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  color: #000;
  font-family: "Raleway-Variable";
  font-size: 60px;
  font-weight: 500;
  text-align: left;
  z-index: 38;
}

.frame-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  z-index: 39;
}
.frame-11 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  z-index: 40;
}
.frame-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  min-height: 0;
  padding: 30px 30px;
  background: #3026f1;
  z-index: 41;
  overflow: hidden;
  border-radius: 32px;
}
.frame-13 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 46px;
  padding: 8px 12px 12px 8px;
  background: #fce6dd;
  z-index: 42;
  border-radius: 100px;
}
.line-arrow-right {
  flex-shrink: 0;
  position: relative;
  z-index: 43;
  overflow: hidden;
}
.transporter-un-colis {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  color: #fff;
  font-family: "Raleway-Extra-Bold-Italic";
  font-size: 26px;
  /* font-weight: 900; */
  /* line-height: 35px; */
  text-align: left;
  text-overflow: initial;
  z-index: 53;
  overflow: hidden;
}

.frame-15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 45;
  text-align: center;
}
.gradient-overlay,
.overlay-without-gradient {
  bottom: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.gradient-overlay {
  background: linear-gradient(0deg, #3026f1, #0000);
}
.envoyer-un-colis {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  color: #fff;
  font-family: "Raleway-Italic";
  font-size: 26px;
  font-weight: 900;
  /* line-height: 35px; */
  text-align: left;
  text-overflow: initial;
  z-index: 46;
  overflow: hidden;
}

@media (min-width: 1920px) {
  .envoyez-vos-colis {
    font-size: 80px !important;
  }
}

/* Large Devices (Desktops, 992px to 1199px) */
@media (min-width: 992px) and (max-width: 1600px) {
  .envoyez-vos-colis {
    font-size: 60px !important;
  }
}

/* Medium Devices (Tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .envoyez-vos-colis {
    font-size: 60px !important;
  }
}

/* Small Devices (Tablets, 576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .envoyez-vos-colis {
    font-size: 50px !important;
  }
}

/* Extra Small Devices (Phones, 575px and down) */
@media (max-width: 575px) {
  .envoyez-vos-colis {
    font-size: 40px;
  }
}
.vous-voulez {
  position: absolute;
  top: calc(50% - 100px);
  left: 50%;
  font-size: 18px;
  color: #3026f1;
  font-family: "Raleway-Extra-Bold-Italic";
}
.vous-voyez-div {
  position: absolute;
  top: 108px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.vous-voyez-two {
  position: absolute;
  text-align: left;
  top: 20%;
  left: 13%;
  font-size: 18px;
  color: #fff;
  font-family: "Raleway-Extra-Bold-Italic";
}
.vous-voyez-two-div {
  position: absolute;
  top: 20% !important;
  right: -5% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vous-voyez-three {
  position: absolute;
  text-align: left;
  top: 25%;
  right: -50%;
  font-size: 18px;
  color: #3026f1;
  font-family: "Raleway-Extra-Bold-Italic";
}

.vous-voyez-child {
  font-family: "Raleway-Variable";
  font-size: 16px;
  font-weight: 500;
  color: "#fff";
}

@media screen and (max-width: 1920px) {
  .envoyez-vos-colis {
    font-size: 60px;
  }
}

@media screen and (max-width: 1120px) {
  .envoyez-vos-colis {
    font-size: 36px;
  }
  .vous-voulez {
    top: 15% !important;
    right: -50% !important;
    font-size: 18px !important;
  }
  .vous-voyez-div {
    position: absolute !important;
    top: 20% !important;
    right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
  }

  .vous-voyez-two {
    position: absolute;
    text-align: left;
    font-weight: 800;
    top: 10%;
    left: 13%;
    font-size: 18px !important;
    color: #fff;
    font-family: "Raleway-Italic";
  }

  .vous-voyez-two-div {
    position: absolute;
    top: 40px !important;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .vous-voyez-three {
    position: absolute;
    text-align: left;
    font-weight: 800;
    top: 25%;
    right: -50%;
    font-size: 18px;
    color: #3026f1;
    font-family: "Raleway-Italic";
  }

  .vous-voyez-three-div {
    position: absolute;
    top: 78px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1870px) {
  .transporter-un-colis {
    font-size: 36px !important;
  }
  .et-nisi-leo {
    font-size: 18px !important;
  }
  .et-nisi-leo-1b {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1070px) {
  .envoyer-un-colis {
    font-size: 18px !important;
  }

  .transporter-un-colis {
    font-size: 16px !important;
  }

  .et-nisi-leo {
    font-size: 12px !important;
  }

  .et-nisi-leo-1b {
    font-size: 12px !important;
  }
  .caption {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 980px) {
  .envoyer-un-colis {
    font-size: 16px !important;
  }

  .transporter-un-colis {
    font-size: 14px !important;
  }

  .et-nisi-leo {
    font-size: 10px !important;
  }

  .et-nisi-leo-1b {
    font-size: 10px !important;
  }
  .caption {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 890px) {
  .envoyer-un-colis {
    font-size: 26px !important;
  }

  .transporter-un-colis {
    font-size: 26px !important;
  }

  .et-nisi-leo {
    font-size: 16px !important;
  }

  .et-nisi-leo-1b {
    font-size: 16px !important;
  }
  .caption {
    font-size: 38px !important;
  }
  .pour-qui {
    margin-top: 120vh !important;
  }

  .vous-voulez {
    top: 15% !important;
    right: -50% !important;
    font-size: 18px !important;
  }
  .vous-voyez-div {
    position: absolute !important;
    top: 20% !important;
    right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
  }

  .vous-voyez-two {
    position: absolute;
    text-align: left;
    font-weight: 800;
    top: 12%;
    left: 11%;
    font-size: 16px !important;
    color: #fff;
    font-family: "Raleway-Italic";
  }
  .vous-voyez-child {
    font-family: "Raleway-Variable";
    font-size: 14px !important;
    font-weight: 500;
    color: "#fff";
  }
  .vous-voyez-two-div {
    position: absolute;
    top: 12px !important;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .vous-voyez-three {
    position: absolute;
    text-align: left;
    font-weight: 800;
    top: 25%;
    right: -50%;
    font-size: 18px;
    color: #3026f1;
    font-family: "Raleway-Italic";
  }

  .vous-voyez-three-div {
    position: absolute;
    top: 78px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.home-page-banner {
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  overflow: unset;
  height: 123%;
}
@media screen and (max-width: 900px) {
  .home-page-banner {
    height: 260%;
  }
}
@media screen and (max-width: 1120px) {
  .envoyer-un-colis {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    /* width: 123px; */
    /* height: 80px; */
    color: #fff;
    font-family: "Raleway-Italic";
    font-size: 22px;
    font-weight: 900;
    line-height: 35px;
    text-align: left;
    text-overflow: initial;
    z-index: 46;
    overflow: hidden;
  }
  .transporter-un-colis {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    /* width: 180px; */
    height: 80px;
    color: #fff;
    font-family: "Raleway-Italic";
    font-size: 20px;
    font-weight: 900;
    line-height: 35px;
    text-align: left;
    text-overflow: initial;
    z-index: 53;
    overflow: hidden;
  }
}
.et-nisi-leo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  /* height: 32px; */
  color: #fff;
  font-family: "Raleway-Variable";
  font-size: 14px;
  font-weight: 400;
  /* line-height: 30px; */
  text-align: left;
  z-index: 47;
}
.frame-16 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  min-height: 0;
  padding: 30px 30px;
  background: #ee6931;
  z-index: 48;
  overflow: hidden;
  border-radius: 32px;
}
.frame-17 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  background: #fff;
  z-index: 49;
  border-radius: 100px;
}
.line-arrow-right-18 {
  flex-shrink: 0;
  position: relative;
  z-index: 50;
  overflow: hidden;
}
.frame-1a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 52;
}
.et-nisi-leo-1b {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  /* width: 204px; */
  /* height: 32px; */
  color: #fff;
  font-family: "Raleway-Variable";
  font-size: 14px;
  font-weight: 400;
  /* line-height: 30px; */
  text-align: left;
  z-index: 54;
}
.frame-1c {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 125px;
  z-index: 55;
}
.je-demenage {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 56;
}
.cest-par-ici {
  flex-shrink: 0;
  position: relative;
  width: 107px;
  font-family: Raleway, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 57;
}
.cest-par-ici-1d {
  position: relative;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-decoration: underline;
}
.frame-1e {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  min-height: 0;
  padding: 130px 0 0 0;
  /* background: url("../../assets/images/3a6d10e3-d9ad-454b-982c-2f3128e3cd12.png")
      no-repeat center; */
  /* background-size: cover; */
  z-index: 58;
  overflow: hidden;
  border-radius: 48px;
  height: 100%;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 40px;
  position: relative;
  width: 100%;
  /* padding: 30px 160px 60px 160px; */
  padding-top: 30px;
  z-index: 59;
  overflow: hidden;
  width: 100%;
}
.frame-1f {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 60;
}
.pourquoi-choisir-frank {
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 56px;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 61;
}
.arguments {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  min-width: 0;
  z-index: 62;
  height: 300px;
}
/* .arguments-20 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    gap: 24px;
    position: relative;
    padding: 0 32px 32px 32px;
    z-index: 63;
  } */
.frame-21 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 134px;
  z-index: 64;
  overflow: hidden;
}

.frame-23 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 66;
}
.fiabilite-et-securite {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 67;
}
.livraisons-ponctuelles {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 48px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  z-index: 68;
}
/* .arguments-24 {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    gap: 24px;
    position: relative;
    padding: 0 32px 32px 32px;
    z-index: 69;
  } */
.frame-25 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 134px;
  z-index: 70;
  overflow: hidden;
}
.frame-26 {
  position: relative;
  width: 164px;
  height: 135px;
  background: url("../../assets/images/f96a9bd38166194738de1fd6b8ef88838f0b0204.png")
    no-repeat center;
  background-size: cover;
  z-index: 71;
}
.frame-27 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 72;
}
.fiabilite-et-securite-28 {
  flex-shrink: 0;
  margin-top: 10px;
  flex-basis: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 73;
}
.livraisons-ponctuelles-29 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  /* width: 384px; */
  height: 48px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  z-index: 74;
}
/* .arguments-2a {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    gap: 24px;
    position: relative;
    padding: 0 32px 32px 32px;
    z-index: 75;
  } */
.frame-2b {
  flex-shrink: 0;
  position: relative;
  height: 134px;
  z-index: 76;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-7 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 128.5px;
  height: 136.631px;
  background: url("../../assets/images/13f08d5eb95db9113c2bda922dc604ba1990ed7a.png")
    no-repeat center;
  background-size: cover;
  z-index: 77;
}
.frame-2c {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 78;
}
.reliability-security {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 79;
}
.delivery-service {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 80;
}
.market-place-2d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  /* width: 1000px; */
  padding: 0 160px 32px 160px;
  z-index: 81;
  overflow: hidden;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 546px;
  z-index: 82;
}
.buy-products {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  text-align: left;
  position: relative;
  /* width: 546px; */
  /* height: 112px; */
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-overflow: initial;
  z-index: 83;
  overflow: hidden;
}
.delivery-partners {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 331px;
  height: 28px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;
  z-index: 84;
}
.arguments-2e {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  min-width: 0;
  z-index: 85;
}
.frame-2f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  z-index: 86;
}

.vous-voyez-desc {
  font-family: "Raleway-Bold";
  font-size: 16px !important;
  color: #000 !important;
  margin-top: 10px !important;
  padding-top: 10px !important;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  background: #fad9b6;
  z-index: 87;
  overflow: hidden;
  border-radius: 32px 32px 12px 12px;
}
.frame-30 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 182px;
  background: #ffffff;
  z-index: 88;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
}
.image {
  position: absolute;
  height: 100%;
  left: 49%;

  background-size: cover;
  transform: translate(-49.09%, 0);
  z-index: 89;
}
.frame-31 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 90;
  overflow: hidden;
  padding: 20px 20px 0px 20px;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 150px;
  z-index: 91;
}
.table-wood {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 92;
}
.time {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 26px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 93;
}
.town-distance {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  z-index: 94;
}
.aix-provence {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 95;
}
.frame-32 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 65px;
  z-index: 96;
}
.solid-car {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 97;
  overflow: hidden;
}

.distance {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 99;
}
.frame-33 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  /* padding: 24px 24px 24px 24px; */
  background: #fad9b6;
  z-index: 100;
  overflow: hidden;
  border-radius: 32px 32px 12px 12px;
}

.frame-35 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 102;
  overflow: hidden;
  padding: 20px;
}
.title-36 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 174px;
  z-index: 103;
}
.table-wood-37 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 104;
}
.time-38 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 105;
}
.town-distance-39 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  /* flex-shrink: 0; */
  position: relative;
  /* width: 124px; */
  z-index: 106;
}
.aix-provence-3a {
  /* flex-shrink: 0; */
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 107;
}
.frame-3b {
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap; */
  flex-shrink: 0;
  gap: 2px;
  background: #fff;
  border-radius: 30px;
  position: relative;
  /* width: 65px; */
  z-index: 108;
  padding: 2px;
}
.solid-car-3c {
  /* flex-shrink: 0; */
  position: relative;
  /* width: 18px; */
  /* height: 18px; */
  z-index: 109;
  /* overflow: hidden; */
  background-color: #fff;
  border-radius: 30px;
}
.solid-3d {
  /* position: relative; */
  /* width: 16.5px; */
  /* height: 13.508px; */
  /* margin: 2.25px 0 0 0.75px; */

  background-size: 100% 100%;
  /* z-index: 110; */
}
.distance-3e {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #000;
  font-family: Raleway, var(--default-font-family);
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 111;
}
.frame-3f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  z-index: 112;
}
.product-40 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 113;
  overflow: hidden;
  border-radius: 32px;
}

.frame-42 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 115;
  overflow: hidden;
}
.title-43 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 174px;
  z-index: 116;
}
.table-wood-44 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 117;
}
.time-45 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 118;
}
.town-distance-46 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  z-index: 119;
}
.aix-provence-47 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 120;
}
.frame-48 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 65px;
  z-index: 121;
}
.solid-car-49 {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 122;
  overflow: hidden;
}

.distance-4b {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 124;
}
.frame-4c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 125;
  overflow: hidden;
  border-radius: 32px;
}
.frame-4d {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 197px;
  background: #ffffff;
  z-index: 126;
  overflow: hidden;
  border-radius: 20px;
}

.frame-4f {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 128;
  overflow: hidden;
}
.title-50 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 174px;
  z-index: 129;
}
.table-wood-51 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 130;
}
.time-52 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 131;
}
.town-distance-53 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  z-index: 132;
}
.aix-provence-54 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 133;
}
.frame-55 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 65px;
  z-index: 134;
}
.solid-car-56 {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 135;
  overflow: hidden;
}

.distance-58 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 137;
}
.frame-59 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  z-index: 138;
}
.product-5a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 139;
  overflow: hidden;
  border-radius: 32px;
}

.frame-5c {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 141;
  overflow: hidden;
}
.title-5d {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 174px;
  z-index: 142;
}
.table-wood-5e {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 143;
}
.time-5f {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 144;
}
.town-distance-60 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  z-index: 145;
}
.aix-provence-61 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 146;
}
.frame-62 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 65px;
  z-index: 147;
}
.solid-car-63 {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 148;
  overflow: hidden;
}

.distance-65 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 150;
}
.frame-66 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 151;
  overflow: hidden;
  border-radius: 32px;
}

.frame-68 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 153;
  overflow: hidden;
}
.title-69 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 174px;
  z-index: 154;
}
.table-wood-6a {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 155;
}
.time-6b {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 156;
}
.town-distance-6c {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 124px;
  z-index: 157;
}
.aix-en-provence {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 158;
}
.frame-6d {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 65px;
  z-index: 159;
}
.solid-car-6e {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 160;
  overflow: hidden;
}

.km {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 162;
}
.button {
  display: flex;
  align-items: right;
  justify-content: right;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 220px;
  padding: 12px 24px 12px 24px;
  background: #2d26e6;
  z-index: 163;
  border-radius: 12px;
  cursor: pointer;
}
.voir-tous-les-produits {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
  z-index: 164;
}
.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  width: 100%;
  padding: 100px 160px 130px 160px;
  background: #f1cbb6;
  /* z-index: 165; */
}

.vector-72 {
  flex-shrink: 0;
  position: absolute;
  width: 2235.231px;
  height: 1442.78px;
  top: 843.001px;
  left: -226px;
  background: url("../../assets/images/02f522e1-6196-45cb-8050-c49c91f879da.png")
    no-repeat center;
  background-size: cover;
  z-index: 168;
}
.menu-73 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 516px;
  z-index: 169;
}
.achetez-vos-produits {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 170;
}
.arguments-74 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 60px;
  position: relative;
  width: 1290px;
  padding: 0 120px 0 120px;
  z-index: 171;
}
.story-75 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 120px;
  /* position: relative; */
  min-width: 0;
  /* z-index: 172; */
}
.frame-76 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  /* position: relative; */
  /* z-index: 173; */
}
.inscription-et-creation-de-profil {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 96px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  z-index: 174;
}
.creer-un-compte {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 72px;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 175;
}
.button-77 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 153px;
  padding: 12px 24px 12px 24px;
  background: #ef6a31;
  z-index: 176;
  border-radius: 12px;
}
.en-savoir-plus {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 177;
  cursor: pointer;
}
.frame-78 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  z-index: 178;
}
.img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 300px;
  z-index: 179;
}
.device {
  flex-shrink: 0;
  position: relative;
  width: 300px;
  height: 628px;
  border: 12.2px solid #e0e0e0;
  z-index: 183;
  border-radius: 30px;
}
.frame-79 {
  flex-shrink: 0;
  position: absolute;
  width: 281px;
  height: 608px;
  top: 8px;
  left: 10.5px;
  background: #ffffff;
  z-index: 181;
  border-radius: 24px;
}
.rectangle {
  position: relative;
  width: 336px;
  height: 427px;
  margin: 105px 0 0 -79px;
  background: url("../../assets/images/f778aba392d02426de373dfd5bb1d760c4081235.png")
    no-repeat center;
  background-size: cover;
  z-index: 182;
}
.top-speaker {
  flex-shrink: 0;
  position: absolute;
  width: 313px;
  height: 603px;
  top: 61px;
  left: 68.645px;
  background: rgba(0, 0, 0, 0.08);
  filter: blur(30px);
  z-index: 184;
  border-radius: 24px;
}

.frame-7b {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 120px;
  position: relative;
  min-width: 0;
  z-index: 185;
}
.img-wrapper-7c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  z-index: 186;
}
.device-7d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 300px;
  z-index: 187;
}
.frame-7e {
  flex-shrink: 0;
  position: relative;
  width: 300px;
  height: 628px;
  border: 12.2px solid #e0e0e0;
  z-index: 191;
  border-radius: 30px;
}
.rectangle-7f {
  flex-shrink: 0;
  position: absolute;
  width: 281px;
  height: 608px;
  top: 8px;
  left: 10.5px;
  background: #ffffff;
  z-index: 189;
  border-radius: 24px;
}

.frame-81 {
  flex-shrink: 0;
  position: absolute;
  width: 313px;
  height: 603px;
  top: 61px;
  left: 68.645px;
  background: rgba(0, 0, 0, 0.08);
  filter: blur(30px);
  z-index: 192;
  border-radius: 24px;
}

.publiez-une-demande {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 193;
}
.button-82 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 144px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  z-index: 194;
}
.en-savoir-plus-83 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 72px;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 195;
}
.story-84 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 153px;
  padding: 12px 24px 12px 24px;
  background: #ef6a31;
  z-index: 196;
  border-radius: 12px;
}
.frame-85 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 197;
}
.suivi-et-reception-du-colis {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 120px;
  position: relative;
  min-width: 0;
  z-index: 198;
}
.suivez-votre-colis {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 199;
}
.button-86 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 96px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  z-index: 200;
}
.en-savoir-plus-87 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 465px;
  height: 72px;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 201;
}
.frame-88 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 153px;
  padding: 12px 24px 12px 24px;
  background: #ef6a31;
  z-index: 202;
  border-radius: 12px;
}
.img-wrapper-89 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 203;
}
.device-8a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  z-index: 204;
}
.frame-8b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 300px;
  z-index: 205;
}
.rectangle-8c {
  flex-shrink: 0;
  position: relative;
  width: 300px;
  height: 628px;
  border: 12.2px solid #e0e0e0;
  z-index: 209;
  border-radius: 30px;
}
.top-speaker-8d {
  flex-shrink: 0;
  position: absolute;
  width: 281px;
  height: 608px;
  top: 8px;
  left: 10.5px;
  background: #ffffff;
  z-index: 207;
  border-radius: 24px;
}
.last {
  position: relative;
  width: 460px;
  height: 449px;
  margin: 47px 0 0 -74px;
  background: url("../../assets/images/b6bb33c00f50668465232c9c05ae52ed477587ee.png")
    no-repeat center;
  background-size: cover;
  z-index: 208;
}
.frame-8e {
  flex-shrink: 0;
  position: absolute;
  width: 313px;
  height: 603px;
  top: 61px;
  left: 68.645px;
  background: rgba(0, 0, 0, 0.08);
  filter: blur(30px);
  z-index: 210;
  border-radius: 24px;
}

.vector-90 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  padding: 60px 160px 60px 160px;
  z-index: 211;
}
.menu-91 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  z-index: 212;
  border-radius: 48px;
}

.frame-93 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 521px;
  z-index: 215;
}
.item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 521px;
  height: 112px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  text-overflow: initial;
  z-index: 216;
  overflow: hidden;
}
 
.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  height: 744px;
  z-index: 218;
}
.right-94 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  min-height: 0;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 219;
  overflow: hidden;
  border-radius: 32px;
}
.title-95 {
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  background: #ffffff;
  z-index: 220;
  overflow: hidden;
  border-radius: 20px;
}

.mise-en-ligne {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 270px;
  z-index: 222;
}
.euro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  min-width: 0;
  z-index: 223;
}
.item-96 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 224;
}
.img-container-97 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 225;
}
.right-98 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 226;
}
.title-99 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  min-height: 0;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 227;
  overflow: hidden;
  border-radius: 32px;
}

.mise-en-ligne-9a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 270px;
  z-index: 229;
}
.euro-9b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  min-width: 0;
  z-index: 230;
}
.item-9c {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 231;
}
.img-container-9d {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 232;
}
.right-9e {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 233;
}
.title-9f {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 32px;
  position: relative;
  min-height: 0;
  padding: 24px 24px 24px 24px;
  background: #ffffff;
  z-index: 234;
  overflow: hidden;
  border-radius: 32px;
}

.box-24 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 270px;
  z-index: 236;
}
.section-20 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  min-width: 0;
  z-index: 237;
}
.table-en-bois-clair-a0 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 238;
}
.mise-en-ligne-a1 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #78778a;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 239;
}
.euro-a2 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 240;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 60px;
  position: relative;
  width: 100%;
  height: 744px;
  padding: 60px 150px 60px 150px;
  background: #2d26e6;
  z-index: 241;
  overflow: hidden;
  border-radius: 32px;
}
.vector-a3 {
  flex-shrink: 0;
  position: absolute;
  width: 2507.181px;
  height: 1972.535px;
  top: 0;
  left: -553.999px;
  background: url("../../assets/images/2f53b9f9-cb3a-419f-a4d4-d798776c9fdf.png")
    no-repeat center;
  background-size: cover;
  z-index: 242;
}
.vector-a4 {
  flex-shrink: 0;
  position: absolute;
  width: 2589.933px;
  height: 2290.814px;
  top: 12px;
  left: -867.999px;
  background: url("../../assets/images/9a7a6e7e-c8e6-4ed6-a4fe-f47f1ab4e5e6.png")
    no-repeat center;
  background-size: cover;
  z-index: 243;
}
.frame-a5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 304px;
  z-index: 244;
}
.market-place-a6 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 304px;
  height: 112px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  text-overflow: initial;
  z-index: 245;
  overflow: hidden;
}
.desktop-wires {
  flex-shrink: 0;
  position: relative;
  width: 1492px;
  height: 900px;
  z-index: 246;
}
.screen-stroke {
  position: relative;
  width: 1440px;
  height: 839px;
  margin: 31px 0 0 26px;
  border: 1px solid #7c7c7c;
  z-index: 266;
  border-radius: 3px;
}
.desktop-gray {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 247;
}
.top-speaker-cam {
  position: relative;
  width: 168px;
  height: 9px;
  margin: 11px 0 0 662px;
  z-index: 262;
}
.rectangle-a7 {
  position: absolute;
  width: 121px;
  height: 9px;
  top: 0;
  left: 50%;
  border: 0.8px solid #7c7c7c;
  transform: translate(-45.45%, 0);
  z-index: 263;
  border-radius: 8px;
}

.device-screen {
  position: absolute;
  width: 1440px;
  height: 839px;
  top: 0;
  left: 0;
  z-index: 250;
}
.browser-bar {
  position: relative;
  width: 1440px;
  height: 40px;
  margin: 0 0 0 0;
  z-index: 252;
}
.rectangle-a9 {
  position: absolute;
  height: 40px;
  top: 0;
  right: 0;
  left: 0;
  background: #bcbbc4;
  z-index: 253;
}
.middle-search {
  position: absolute;
  width: 608px;
  height: 22px;
  top: 9px;
  left: 417px;
  z-index: 258;
}
.rectangle-aa {
  position: absolute;
  width: 608px;
  height: 22px;
  top: 0;
  left: 50%;
  background: #f6f6f6;
  transform: translate(-50%, 0);
  z-index: 259;
  border-radius: 10px;
}
.website {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 128px;
  height: 15px;
  top: 3px;
  left: calc(50% - 65px);
  font-family: Circular Pro, var(--default-font-family);
  font-size: 12px;
  font-weight: 450;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
  z-index: 261;
}

.buttons {
  position: absolute;
  width: 82px;
  height: 19px;
  top: 10px;
  left: 24px;
  z-index: 254;
}
.rectangle-ab {
  position: absolute;
  width: 19px;
  height: 19px;
  top: 0;
  left: 0;
  background: #fce6dd;
  z-index: 255;
}

.insert-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 251;
  border-radius: 3px;
}
.device-ad {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  z-index: 248;
  border-radius: 38px;
}
.frame-ae {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: absolute;
  width: 56px;
  height: 56px;
  /* top: 32px; */
  /* left: 628px; */
  padding: 12px 12px 12px 12px;
  background: #ffffff;
  z-index: 267;
  border-radius: 100px;
}
.arrow-right {
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 268;
  overflow: hidden;
}

.why {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 36px;
  position: relative;
  width: 100%;
  padding: 32px 160px 32px 160px;
  z-index: 270;
  overflow: hidden;
}
.menu-b0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 652px;
  z-index: 271;
}
.market-place-b1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 652px;
  height: 56px;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  white-space: nowrap;
  z-index: 272;
}
.arguments-b2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  width: 796px;
  z-index: 273;
}
.arguments-b3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 796px;
  height: 98px;
  padding: 0 32px 32px 32px;
  z-index: 274;
}
.frame-b4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 275;
}
.reliability-security-b5 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #2d26e6;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 276;
}
.deliveries {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 732px;
  height: 48px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  z-index: 277;
}
.button-b6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 123px;
  padding: 12px 24px 12px 24px;
  background: #ef6a31;
  z-index: 278;
  border-radius: 12px;
}
.discover {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 279;
}
.reco {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  width: 1728px;
  padding: 110px 0 110px 0;
  background: #f1cbb6;
  z-index: 280;
  overflow: hidden;
}
.vector-b7 {
  flex-shrink: 0;
  position: absolute;
  width: 2346.813px;
  height: 1635.432px;
  top: 171px;
  left: -361.001px;
  background: url("../../assets/images/1a8694fb-0ec9-4be8-b361-0b83c0c086e0.png")
    no-repeat center;
  background-size: cover;
  z-index: 281;
}
.menu-b8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 543px;
  z-index: 282;
}
.recommendation {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 283;
}
.delivery {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 425px;
  height: 28px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;
  z-index: 284;
}
.frame-b9 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  padding: 0 0 0 160px;
  z-index: 285;
  overflow: hidden;
}
.testimonials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 659px;
  padding: 48px 48px 48px 48px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 286;
  overflow: hidden;
  border-radius: 40px;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 287;
}
.jacques-c {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 288;
}
.testimonial {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 563px;
  height: 100px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 289;
}
.stars {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 232px;
  z-index: 290;
}
.star {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 291;
  overflow: hidden;
}

.star-bb {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 293;
  overflow: hidden;
}

.star-bd {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 295;
  overflow: hidden;
}

.star-bf {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 297;
  overflow: hidden;
}

.star-c1 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 299;
  overflow: hidden;
}

.testimonials-c3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 659px;
  padding: 48px 48px 48px 48px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 301;
  overflow: hidden;
  border-radius: 32px;
}
.text-c4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 302;
}
.henriette-d {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 303;
}
.testimonial-c5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 563px;
  height: 100px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 304;
}
.stars-c6 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 232px;
  z-index: 305;
}
.star-c7 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 306;
  overflow: hidden;
}

.star-c9 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 308;
  overflow: hidden;
}

.star-cb {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 310;
  overflow: hidden;
}

.star-cd {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 312;
  overflow: hidden;
}

.star-cf {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 314;
  overflow: hidden;
}

.testimonials-d1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 659px;
  padding: 48px 48px 48px 48px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 316;
  overflow: hidden;
  border-radius: 32px;
}
.text-d2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 317;
}
.jacques-c-d3 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 318;
}
.cela-fait-ans {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 563px;
  height: 100px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 319;
}
.stars-d4 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 232px;
  z-index: 320;
}
.line-star {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 321;
  overflow: hidden;
}

.line-star-d6 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 323;
  overflow: hidden;
}

.line-star-d8 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 325;
  overflow: hidden;
}

.line-star-da {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 327;
  overflow: hidden;
}

.line-star-dc {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 329;
  overflow: hidden;
}

.testimonials-de {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 659px;
  padding: 48px 48px 48px 48px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 331;
  overflow: hidden;
  border-radius: 32px;
}
.text-df {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 332;
}
.henriette-d-e0 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 333;
}
.lorem-ipsum-dolor {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 563px;
  height: 100px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 334;
}
.stars-e1 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 232px;
  z-index: 335;
}
.line-star-e2 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 336;
  overflow: hidden;
}

.line-star-e4 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 338;
  overflow: hidden;
}

.line-star-e6 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 340;
  overflow: hidden;
}

.line-star-e8 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 342;
  overflow: hidden;
}

.line-star-ea {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 344;
  overflow: hidden;
}

.button-ec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 246px;
  padding: 12px 24px 12px 24px;
  background: #ef6a31;
  z-index: 346;
  border-radius: 12px;
}
.voir-tous-les-produits-ed {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 347;
}
.news {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 160px;
  position: relative;
  min-width: 0;
  padding: 100px 160px 100px 160px;
  background: #fce6dd;
  z-index: 348;
  overflow: hidden;
}

.actus {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  padding: 0px 0 60px 0;
  z-index: 356;
}
.menu-f5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 425px;
  z-index: 357;
}
.achetez-vos-produits-f6 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 56px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  white-space: nowrap;
  z-index: 358;
}
.text-56 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 425px;
  height: 28px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;
  z-index: 359;
}
.frame-f7 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  padding: 0 160px 0 160px;
  z-index: 360;
  overflow: hidden;
  width: 1000px;
}
.frame-f8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  width: 450px;
  z-index: 361;
}
.actu {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  /* width: 350px; */
  padding: 0 0 24px 0;
  background: #fad9b6;
  z-index: 362;
  overflow: hidden;
  border-radius: 32px;
}
.img-wrapper-blue {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 197px;
  /* background: #2d26e6; */
  background: url("../../assets/images/FrankIcons/trainImage.jpg") no-repeat
    center;
  z-index: 363;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}
.pic-26-1 {
  position: relative;
  width: 363px;
  height: 228px;
  margin: 0;
  background: url("../../assets/images/FrankIcons/trainImage.jpg") no-repeat
    center;
  background-size: cover;
  z-index: 364;
}

.pic-26-2 {
  position: relative;
  width: 363px;
  height: 228px;
  margin: 0;
  background: url("../../assets/images/FrankIcons/boxWithHand.jpg") no-repeat
    center;
  background-size: cover;
  z-index: 364;
}
.pic-26-3 {
  position: relative;
  width: 363px;
  height: 228px;
  margin: 0;
  background: url("../../assets/images/FrankIcons/cupboardImage.jpg") no-repeat
    center;
  background-size: cover;
  z-index: 364;
}
.text-f9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  padding: 0 24px 0 24px;
  z-index: 365;
}
.tilte {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 366;
  overflow: hidden;
}
.frame-fa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 223px;
  z-index: 367;
}
.frame-fb {
  display: flex;
  justify-content: center;
  gap: 4px;
  width: 100%;
  z-index: 368;
}
.du-nouveau-chez-frank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 369;
}
.il-y-a-minutes {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 370;
}
.lorem-ipsum-dolor-fc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 72px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 371;
}
.voir-lartcile {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 372;
  margin-top: 10px;
}
.actu-fd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 450px;
  padding: 0 0 24px 0;
  z-index: 373;
  overflow: hidden;
  border-radius: 32px;
}
.img-wrapper-orange {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 197px;
  background: #ef6a31;
  z-index: 374;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}
.pic-27 {
  position: relative;
  width: 362px;
  height: 181px;
  margin: 40px 0 0 44px;
  background: url("../../assets/images/2d3a1a6534b323ca114024b7648be946f62cf1ae.png")
    no-repeat center;
  background-size: cover;
  z-index: 375;
}
.text-fe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  padding: 0 24px 0 24px;
  z-index: 376;
}
.tilte-ff {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 377;
  overflow: hidden;
}
.frame-100 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 223px;
  z-index: 378;
}
.frame-101 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 223px;
  z-index: 379;
}
.du-nouveau-chez-frank-102 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 380;
}
.il-y-a-minutes-103 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 381;
}
.lorem-ipsum-dolor-104 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 402px;
  height: 72px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 382;
}
.voir-lartcile-105 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 383;
}
.frame-106 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  width: 450px;
  padding: 48px 0 0 0;
  z-index: 384;
}
.actu-107 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 450px;
  padding: 0 0 24px 0;
  background: #ffffff;
  z-index: 385;
  overflow: hidden;
  border-radius: 32px;
}
.img-wrapper-orange-108 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 197px;
  background: #ef6a31;
  z-index: 386;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}
.pic-28 {
  position: relative;
  width: 304px;
  height: 314px;
  margin: 22px 0 0 73px;
  background: url("../../assets/images/b9ce3c9cebf3e316cdff0e861b58bf578889090b.png")
    no-repeat center;
  background-size: cover;
  z-index: 387;
}
.text-109 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  padding: 0 24px 0 24px;
  z-index: 388;
}
.tilte-10a {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 389;
  overflow: hidden;
}
.frame-10b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 223px;
  z-index: 390;
}
.frame-10c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 223px;
  z-index: 391;
}
.du-nouveau-chez-frank-10d {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 392;
}
.il-y-a-minutes-10e {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 393;
}
.lorem-ipsum-dolor-10f {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 402px;
  height: 72px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 394;
}
.voir-lartcile-110 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 395;
}
.actu-111 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 450px;
  padding: 0 0 24px 0;
  background: #ffffff;
  z-index: 396;
  overflow: hidden;
  border-radius: 32px;
}
.img-wrapper-blue-112 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 197px;
  background: #2d26e6;
  z-index: 397;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}

.div-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  padding: 0 24px 0 24px;
  z-index: 399;
}
.div-title {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 400;
  overflow: hidden;
}
.div-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 223px;
  z-index: 401;
}
.div-frame-113 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 223px;
  z-index: 402;
}
.span-new-frank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #080626;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  white-space: nowrap;
  z-index: 403;
}
.span-5-minutes {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #4b4a61;
  font-family: Montserrat, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 404;
}
.span-lorem-ipsum {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 402px;
  height: 72px;
  color: #4b4a61;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 405;
}
.span-view-article {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ef6a31;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;
  z-index: 406;
}
.div-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 203px;
  padding: 12px 24px 12px 24px;
  background: #2d26e6;
  z-index: 407;
  border-radius: 12px;
}
.span-view-all-products {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 408;
}
.div-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  background: #f5c9b3;
  /* gap: 48px; */
  position: relative;
  z-index: 409;
  width: 1000px;
  border-radius: 20px;
}
.div-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 410;
  padding: 10px;
}
.span-marketplace-questions {
  flex-basis: auto;
  position: relative;
  color: #000;
  font-family: "Raleway-Italic";
  font-size: 42px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  white-space: nowrap;
  z-index: 411;
}
.div-frame-114 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  /* gap: 48px; */
  position: relative;
  min-width: 0;
  /* padding: 0 160px 0 160px; */
  z-index: 412;
}
.div-frame-1-1-4 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  /* padding: 0 160px 0 160px; */
  z-index: 412;
}
.div-frame-115 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  /* gap: 32px; */
  position: relative;
  /* width: 1000px; */
  z-index: 413;
}

.div-frame-116 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 415;
  width: 100%;
}
.span-what-is-frank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: "Raleway-Variable";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  /* white-space: nowrap; */
  z-index: 416;
}
.span-what-is-frank_mobile {
  height: 32px;
  color: #281208;
  font-family: "Raleway-Variable";
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  width: 100%;
  z-index: 416;
}

 
.div-line-chevron-down {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 417;
  overflow: hidden;
}

.div-line-117 {
  height: 1px;
  background-color: #000;
}

.div-line-117-mb {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 1px;
  border-top: 1px solid #fff;
  background-size: cover;
  z-index: 419;
  padding-right: 10px !important;
}
.div-frame-118 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 420;
}
.span-how-does-delivery-work {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 421;
}
.div-line-chevron-down-119 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 422;
  overflow: hidden;
}

.div-frame-11c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 425;
}
.span-what-happens-if-my-package-is-lost-stolen {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 426;
}
.div-line-chevron-down-11d {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 427;
  overflow: hidden;
}

.div-frame-120 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 430;
}
.span-is-there-a-weight-limit {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 431;
}
.div-line-chevron-down-121 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 432;
  overflow: hidden;
}

.div-seo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  min-width: 0;
  padding: 60px 160px 60px 160px;
  z-index: 435;
}
.div-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  padding: 48px 48px 48px 48px;
  background: #f1cbb6;
  z-index: 436;
  overflow: hidden;
  border-radius: 40px;
}
.div-vector {
  flex-shrink: 0;
  position: absolute;
  width: 2531.475px;
  height: 2038.318px;
  top: -260px;
  left: -824.001px;
  background: url("../../assets/images/1a4a8ff5-3063-4158-9d7a-c9e1a5849b95.png")
    no-repeat center;
  background-size: cover;
  z-index: 437;
}
.div-menu-124 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 250px;
  z-index: 438;
}
.span-marketplace-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 250px;
  height: 112px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
  text-overflow: initial;
  z-index: 439;
  overflow: hidden;
}
.div-frame-125 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  z-index: 440;
}
.frame-126 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  z-index: 441;
}
.tout-savoir {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 442;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
}
.solid-heart-circle {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 443;
}

.redirection {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 445;
}
.tout-savoir-128 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 446;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
}
.solid-heart-circle-129 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 447;
}

.redirection-12b {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #281208;
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 449;
}
.tout-savoir-12c {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 10px;
  position: relative;
  padding: 24px 24px 24px 24px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 450;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
}

.background {
  display: flex;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  background: #2d26e6;
  z-index: 480;
  overflow: hidden;
  width: 93%;
  border-radius: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 42px;
  position: relative;
  padding: 60px 0 60px 0;
  z-index: 484;
}
.frame-14b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 1px;
  position: relative;
  width: 472.11px;
  z-index: 485;
}

.download-customer-app {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 35px;
  color: #ffffff;
  font-family: Raleway, var(--default-font-family);
  font-size: 21px;
  font-weight: 400;
  line-height: 34.65px;
  text-align: left;
  white-space: nowrap;
  z-index: 488;
}

.link {
  flex-shrink: 0;
  position: relative;
  width: 164px;
  height: 48.58px;
  z-index: 490;
}

.flex-row-d {
  position: relative;
  width: 57.028px;
  height: 5.972px;
  margin: 2.511px 0 0 8.035px;
  z-index: 524;
}

.flex-row {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 528;
}

.line-arrow-right-173 {
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 535;
  overflow: hidden;
}

.frame-175 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 674.8px;
  z-index: 537;
}

@media only screen and (max-width: 1210px) {
  .frame-175 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* align-self: stretch; */
    /* flex-wrap: nowrap; */
    /* flex-shrink: 0; */
    /* position: relative; */
    /* min-width: 0; */
    height: 774.8px;
    z-index: 537;
  }
}

.frame-176 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: absolute;
  width: 1408px;
  height: 227px;
  top: 84px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 538;
}
.tracking {
  display: flex;
  /* align-items: center; */
  /* align-self: stretch; */
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 40px;
  position: relative;
  min-width: 0;
  padding: 48px 48px 48px 48px;
  background: #ffffff;
  z-index: 1111111;
  border-radius: 30px;
  box-shadow: 0 -30px 100px 0 rgba(0, 37, 133, 0.03);
}

.group-179 {
  position: relative;
  width: 68.541px;
  height: 73.228px;
  margin: -5.272px 0 0 1.757px;
  z-index: 544;
}

.frame-17d {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-basis: 0;
  gap: 16px;
  position: relative;
  height: 131px;
  z-index: 548;
}
.heading-tracking {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 96.048px;
  height: 28.8px;
  color: #ee6931;
  font-family: Raleway, var(--default-font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  white-space: nowrap;
  z-index: 549;
}
.click-here-to-track {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 468px;
  height: 70px;
  color: #0b3c6a;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  text-overflow: initial;
  z-index: 550;
  overflow: hidden;
}
.support {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 40px;
  position: relative;
  min-width: 0;
  padding: 48px 48px 48px 48px;
  background: #ffffff;
  z-index: 551;
  border-radius: 30px;
  box-shadow: 0 -30px 100px 0 rgba(0, 37, 133, 0.03);
}
.icon-svg-17e {
  position: relative;
  width: 83px;
  height: 66px;
  z-index: 552;
  overflow: hidden;
}
.icon-svg-fill-17f {
  position: absolute;
  width: 83px;
  height: 66px;
  top: 0;
  left: 0;
  z-index: 553;
  overflow: hidden;
}

.frame-181 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-basis: 0;
  gap: 16px;
  position: relative;
  z-index: 555;
}
.div-line-123 {
  height: 1px;
  background-color: #fff;
}
.heading-support {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28.8px;
  color: #ee6931;
  font-family: Raleway, var(--default-font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  white-space: nowrap;
  z-index: 556;
}
.question-inquiry {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 35px;
  color: #0b3c6a;
  font-family: Raleway, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  white-space: nowrap;
  z-index: 557;
}
.link-182 {
  flex-shrink: 0;
  position: relative;
  width: 174.47px;
  height: 35px;
  z-index: 558;
}
.phone-number {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 35px;
  top: 0;
  left: 0;
  color: #000000;
  font-family: Raleway, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  white-space: nowrap;
  z-index: 559;
}
.footer {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 449px;
  background: #2d26e6;
  z-index: -1111111;
  overflow: hidden;
}
.flex-column-c {
  position: absolute;
  top: 0;
  right: 325px;
  bottom: 24px;
  left: 0;
  z-index: 603;
}

.container-184 {
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  /* width: 1080px; */
  height: 178px;
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-35.05%, -42.98%); */
  z-index: 562;
}
.logo {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  flex-wrap: nowrap;
  gap: 16px;
  position: relative;
  /* width: 129px; */
  z-index: 563;
}
.link-185 {
  flex-shrink: 0;
  position: relative;
  /* width: 129px; */
  height: 66.64px;
  z-index: 564;
}
.white-logo-svg {
  position: absolute;
  /* width: 129px; */
  height: 66.64px;
  top: 0;
  left: 0;
  z-index: 565;
  overflow: hidden;
}
.white-logo-fill {
  position: absolute;
  width: 129px;
  height: 66.64px;
  top: 0;
  left: 0;
  z-index: 566;
  overflow: hidden;
}
.white-logo-svg-186 {
  position: relative;
  width: 128.98px;
  height: 66.64px;
  margin: 0 0 0 0.01px;
  z-index: 567;
  overflow: hidden;
}
.group-187 {
  position: relative;
  width: 124.577px;
  height: 66.081px;
  margin: 0 0 0 0;
  z-index: 568;
}

.list {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 570;
}
.item-link {
  flex-shrink: 0;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.17);
  z-index: 571;
  border-radius: 6px;
}

.item-link-188 {
  flex-shrink: 0;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.17);
  z-index: 573;
  border-radius: 6px;
}

.item-link-18a {
  flex-shrink: 0;
  position: relative;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.17);
  z-index: 575;
  border-radius: 6px;
}

.list-18c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  /* width: 180px; */
  z-index: 577;
}
.item-link-18d {
  flex-shrink: 0;
  position: relative;
  width: 45.36px;
  height: 44.5px;
  z-index: 578;
}
.home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 16.8671875px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 579;
}
.item-link-18e {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 44.5px;
  z-index: 580;
}
.my-order-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 581;
}
.item-link-18f {
  flex-shrink: 0;
  position: relative;
  width: 115.3px;
  height: 44.5px;
  z-index: 582;
}
.support-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 583;
}
.item-link-190 {
  flex-shrink: 0;
  position: relative;
  width: 113.38px;
  height: 44.5px;
  z-index: 584;
}
.customer-help {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 585;
}
.list-191 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  /* width: 180px; */
  z-index: 586;
}
.item-link-192 {
  flex-shrink: 0;
  position: relative;
  width: 40.63px;
  height: 44.5px;
  z-index: 587;
}
.rider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 588;
}
.item-link-193 {
  flex-shrink: 0;
  position: relative;
  width: 66.13px;
  height: 44.5px;
  z-index: 589;
}
.air-rider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 590;
}
.item-link-194 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 44.5px;
  z-index: 591;
}
.fleet-manager {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 592;
}
.item-link-195 {
  flex-shrink: 0;
  position: relative;
  width: 76.55px;
  height: 44.5px;
  z-index: 593;
}
.sub-rider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 594;
}
.list-196 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  /* width: 180px; */
  z-index: 595;
}
.item-link-197 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 44.5px;
  z-index: 596;
}
.e-merchant-store {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 597;
}
.item-link-198 {
  flex-shrink: 0;
  position: relative;
  width: 93.56px;
  height: 44.5px;
  z-index: 598;
}
.space-store {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 599;
}
.item-link-199 {
  flex-shrink: 0;
  position: relative;
  width: 79.38px;
  height: 44.5px;
  z-index: 600;
}
.d2c-store {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 44px;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.85);
  font-family: Roboto, var(--default-font-family);
  font-size: 17px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  white-space: nowrap;
  z-index: 601;
}
.frank {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 981.63px;
  height: 19px;
  bottom: 0;
  left: calc(50% - 500.5px);
  color: rgba(255, 255, 255, 0.85);
  font-family: Raleway, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 18.784px;
  text-align: center;
  white-space: nowrap;
  z-index: 561;
}

/* Slider Css */

.slider_container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 48px;
  z-index: 1111;
}

.slick-slider {
  padding-left: 30px;
}

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  animation: 18s cubic-bezier(1, 0.95, 0.565, 1) sliding infinite;
}

.slide {
  position: relative;
  min-width: 100%;
  height: 100%;
  width: 100%;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Container for the card */
.blogcard-container {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  background-color: #fbd7a4;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: "Raleway", sans-serif;
  margin: 0 auto;
}

/* Image Section */
.blogcard-image {
  width: 100%;
  height: 200px;
}

/* Card Content */
.blogcard-content {
  padding: 16px;
}

.blogcard-title {
  margin: 0;
  font-size: 18px;
  color: #000;
}

.blogcard-subtitle {
  font-style: italic;
  color: #6d6d6d;
  margin-top: 4px;
  margin-bottom: 12px;
}

.blogcard-description {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 12px;
}

/* Arrow Icon */
.blogcard-arrow {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .blogcard-container {
    max-width: 600px;
  }

  .blogcard-content {
    padding: 20px;
    flex: 1;
  }

  .blogcard-title {
    font-size: 20px;
  }

  .blogcard-description {
    font-size: 16px;
  }
}

.caption_container {
  position: absolute;
  top: 75%;
  left: 5%;
  color: white;
  text-align: left;
  z-index: 1;
  margin-bottom: 10px;
}

.caption {
  font-size: 2rem;
  font-family: "Raleway-Extra-Bold-Italic";
  border-radius: 0.5rem;
  text-align: left;
}

.caption_line {
  width: 120px;
  height: 8px;
  background: white;
  margin-left: 0px;
  border-radius: 20px;
  margin-top: 0.5rem;
}

.dots {
  position: absolute;
  bottom: 2%;
  left: 60%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  opacity: 0.7;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.dot.active {
  background: #ee6931; /* Active slide dot color */
  opacity: 1;
}

@keyframes sliding {
  0% {
    transform: translateX(0%);
  }
  33.33% {
    transform: translateX(0%);
  }
  38.33% {
    transform: translateX(-100%);
  }
  71.66% {
    transform: translateX(-100%);
  }
  76.66% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

/* slider CSS */

/* Slider container */
.slider-wrapperNew {
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 32px;
  background: linear-gradient(
    to bottom,
    #f9b484,
    #0c49a5
  ); /* Gradient for overall slider */
}

/* Slider */
.sliderNew {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}
.slideNew::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #3026f1, transparent 30%);
  z-index: 1; /* Places the gradient on top of the image */
  pointer-events: none; /* Ensures gradient doesn't interfere with interactions */
}
/* Individual slide */
.slideNew {
  min-width: 100%;
  position: relative;
}

/* Slide image */
.slideNew img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  position: relative;
  z-index: 0; /* Ensure the image is behind the gradient */
}

/* Caption styles */
.captionNew {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 4px;
  z-index: 111111;
}

/* Dots container */
.slider-dotted {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  z-index: 2; /* Ensure dots are above the gradient */
}

/* Individual dot */
.dotted {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active dot */
.dotted.active {
  background-color: #ff7f50; /* Custom active color */
}
/* ///// */
.slick-prev,
.slick-next {
  font-size: 12px !important;
  line-height: 0 !important;
  position: absolute !important;
  top: 50% !important;
  display: block !important;
  border-radius: 30px !important;
  width: 50px !important;
  height: 50px !important;
  padding: 0 !important;
  transform: translate(0, -50%) !important;
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  background: #3026f1 !important;
  padding: 10px !important;
}
.slick-prev {
  color: #fff !important;
  left: -55px !important;
}
.slick-next {
  color: #fff !important;
  right: -55px !important;
}

.footer-new {
  width: 100%;
  position: relative;
  background-color: #3026f1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contain {
  padding: 0px 0px 0px 0px;
}
.footer-new .col {
  width: 170px;
  height: auto;
  float: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 20px 20px 20px;
}
.footer-new .col h1 {
  margin: 0;
  padding: 0;
  font-family: "Raleway-Italic";
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 20px 0px 5px 0px;
  color: #fff;
}
.footer-new .col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-new .col ul li {
  color: #fff;
  font-size: 14px;
  font-family: "Raleway-Italic";
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
}
.social ul li {
  display: inline-block;
  padding-right: 5px !important;
}

.footer-new .col ul li:hover {
  color: #ffffff;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
}
.clearfix {
  clear: both;
}
@media only screen and (min-width: 1280px) {
  .contain {
    width: 1100px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1139px) {
  .contain .social {
    width: 1000px;
    display: block;
  }
  .social h1 {
    margin: 0px;
  }
}
@media only screen and (max-width: 950px) {
  .footer-new .col {
    width: 33%;
  }
  .footer-new .col h1 {
    font-size: 14px;
  }
  .footer-new .col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 500px) {
  .footer-new .col {
    width: 50%;
  }
  .footer-new .col h1 {
    font-size: 14px;
  }
  .footer-new .col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 340px) {
  .footer-new .col {
    width: 100%;
  }
}

.story-background {
  background: url("../../assets/images/FrankIcons/dotted@4x.png") no-repeat
    center;
  background-color: #f9d9b6;
  height: 100%;
}

.mstpone {
  background-color: #f9d9b6;
  background-image: url("../../assets/images/FrankIcons/dotted@4x.png");
  min-height: 150px;
  width: 100%;
  background-size: cover;
}

.mstpone_1 {
  background-color: #f9c7b8;
  background-image: url("../../assets/images/FrankIcons/dotted-small.png");
  min-height: 150px;
  width: 100%;
  background-size: 1024px;
}
 
.mstpone .mst-srcone .imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}
 
.mstpone .mst-srcone .imgtxt .txt {
  position: absolute;
  top: 108px;
  right: 240px;
}

.mstptwo {
  background-color: #3026f1;
  /* min-height: 150px; */
  width: 100%;
}
 
.mstptwo .mst-srctwo .imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}
 
.mstptwo .mst-srctwo .imgtxt .txt {
  position: absolute;
  top: 94px;
  left: 218px;
}

.mstpthree {
  background-color: #f9d9b6;
  background-image: url("../../assets/images/FrankIcons/dotted@4x.png");
    width: 100%;
  background-size: cover;
}

.mstpthree-update {
  background-color: #f9d9b6;
  background-image: url("../../assets/images/FrankIcons/dotted@4x.png");

  min-height: 375px !important;

  width: 100%;
  max-height: 489px;
  background-size: cover;
}
.mstpthree-mob {
  background-color: #f9d9b6;
  background-image: url("../../assets/images/FrankIcons/dotted@4x.png");

  min-height: 375px !important;

  width: 100%;
  background-size: cover;
}
 
.mstpthree .mst-srcthree .imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}

.imgtxt-update {
  height: calc(100vh - 14vh);
}
.img-height-imp {
  height: 100% !important;
}

.mstpthree .mst-srcthree .imgtxt img {
  width: 100%;
}
.mstpthree .mst-srcthree .imgtxt .txt {
  position: absolute;
  top: 134px;
  right: 240px;
}
@media (max-width: 1349px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 240px;
    top: 108px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 218px;
    top: 80px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 240px;
    top: 144px;
  }
}
@media (max-width: 1600) {
  .mstpthree {
    min-height: 375px !important;
  }
  .mstpone .mst-srcone .imgtxt .txt {
    right: 240px;
    top: 108px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 218px;
    top: 80px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 240px;
    top: 144px;
  }
}
@media (max-width: 1283px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 240px;
    top: 108px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 218px;
    top: 80px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 240px;
    top: 134px;
  }
}
@media (max-width: 1164px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 214px;
    top: 94px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 194px;
    top: 66px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 120px;
    top: 120px;
  }
}
@media (max-width: 1021px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 90px;
    top: 74px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 90px;
    top: 74px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 90px;
    top: 74px;
  }
}
@media (max-width: 1021px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 185px;
    top: 88px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 160px;
    top: 55px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 70px;
    top: 100px;
  }
}
@media (max-width: 890px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 140px;
    top: 82px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 127px;
    top: 38px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 40px;
    top: 96px;
  }
}
@media (max-width: 800px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 100px;
    top: 58px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    left: 99px;
    top: 30px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 10px;
    top: 74px;
  }
}
@media (max-width: 768px) {
  .mstpone .mst-srcone .imgtxt .txt {
    right: 0px;
  }
  .mstptwo .mst-srctwo .imgtxt .txt {
    right: 0px;
  }
  .mstpthree .mst-srcthree .imgtxt .txt {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .mstpone {
    display: flex;
  }
  .mstptwo {
    display: flex;
  }
  .mstpthree {
    display: flex;
  }
}
@media (max-width: 480px) {
}
/* blogs card */

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  background-color: white;
  border: 1px solid lightgray;
  /* //border-radius: 1rem; */
  color: grey;
  padding: 0.5rem;
  text-transform: lowercase;
}

.btn--block {
  display: block;
  width: 100%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  justify-content: center;
  padding: 0;
  gap: 10 !important;
}

.cards__item {
  display: flex;
  padding: 1rem;
  cursor: pointer;
  width: 320px !important;
  @media (min-width: 40rem) {
    width: 33.3333%;
  }
  @media (min-width: 56rem) {
    width: 33.3333%;
  }
}

.card {
  background-color: #fad9b6;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 17px;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  /* filter: contrast(70%); */
  /* filter: saturate(180%); */
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
  @media (min-width: 40rem) {
    &::before {
      padding-top: 60.6%;
    }
  }
}

.card__image--flowers {
  background-image: url("../../assets/images/FrankIcons/trainImage.jpg");
}

.card__image--river {
  background-image: url("../../assets/images/FrankIcons/boxWithHand.jpg");
}

.card__image--record {
  background-image: url("../../assets/images/FrankIcons/cupboardImage.jpg");
}

.card__title {
  color: #000;
  font-size: 19px;
  font-family: "Raleway-Extra-Bold";
}

.card__text {
  flex: 1 1 auto;
  font-size: 14px;
  /* line-height: 1.5; */
  /* margin-bottom: 1.25rem; */
  font-family: "Raleway-Variable";
}
