.mobile-input-background {
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    display: flex;
    border: 1px solid #f4f3f6;
    border-radius: 4px;
    margin-top: 5px;
    width: 100%;
  }
  .mobile-input-background-red {
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    display: flex;
    border: 1px solid red;
    border-radius: 4px;
  }
  .mobile-input-background-disabled {
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f4f3f6;
    position: relative;
    display: flex;
    border: 1px solid #f4f3f6;
    border-radius: 4px;
  }
  .mobile-container {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

  }
  .mobile-container:hover {
    border: 1px solid #0e88de;
  }
  .mobile-container-flag {
    height: 35px;
    object-fit: contain;
    padding-left: 10px;
    padding-right: 5px;
    filter: drop-shadow(rgba(34, 34, 34, 0.486) 0px 1px 2px);
  }
  .mobile-container-flag-small {
    height: 30px;
    object-fit: contain;
    padding-left: 5x;
    padding-right: 1px;
    filter: drop-shadow(rgba(34, 34, 34, 0.486) 0px 1px 2px);
  }
  .mobile-container-caret {
    height: 10px;
    object-fit: contain;
    padding-left: 2px;
    padding-right: 2px;
  }
  .mobile-container-input-container,
  .mobile-container-input {
    flex: 1;
    width: 100% !important;
    font-size:18px ;
    font-family: "Raleway-Variable";
    /* padding-top: 2.5px; */
    height: 92%;
  }
  .mobile-container-input {
    background-color: transparent;
    border: none;
    font-weight: 500;
    color: rgb(15, 15, 15);
    font-size: 18px;
  }
  .mobile-container-input:focus,
  .mobile-container-input:active {
    border: none !important;
    outline: none !important;
  }
  .mobile-container-countrycode {
    background-color: transparent;
    font-family: "Raleway-Variable";
    border: none;
    width: 35px !important;
    color: black;
    font-weight: 500;
    font-size: 16px;
    padding-left: 0px;
    padding-right: 5px;
  }
  .flag-item {
    background-color: #f4f3f6;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .flag-item:hover {
    background-color: whitesmoke;
  }
  .mobile-list {
    height: 470px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  /* .mobile-container-search{
      background-color: transparent;
      border: none;
      color: rgb(0, 0, 0);
      font-size: 18px;
      w
  } */
  .mobile-list-width {
    padding: 0px !important;
    width: 100%;
    min-width: 433px;
  }
  .mobile-flag-icon {
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 1px 2px);
  }
  @media screen and (max-width: 720px) and (orientation: portrait) {
    .mobile-list {
      height: calc(100% - 95px) !important;
    }
    .mobile-list-width {
      min-width: 100% !important;
    }
  }
  .cursor-pointer {
    cursor: pointer;
    /* padding-top: 5px; */
  }
  .cursor-pointer-caret {
    cursor: pointer;
    /* padding-top: 2.5px; */
    display: flex;
  }
  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }
  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }
  .input-field:focus {
    border: 2px solid dodgerblue;
  }
  