.b-main-div {
  height: calc(90vh + 190px);
  padding: 0px 40px;
  display: flex;
  align-items: center;
  width: 70%;
}
.b-home-banner-inner-div {
  padding: 0px 100;
  transform: translateY(-200px);
}
.b-home-banner-inner-text {
  font-family: "Raleway-Italic";
  font-weight: 900;
  font-size: 58px;
  color: #fff;
}
.b-home-banner-inner-text-two {
  font-family: "Raleway-Italic";
  font-size: 26px;
  color: #3026f1;
  /* background: #ffab91; */
  margin-top: 10px;
}

.b-app-store-btn {
  /* position: absolute;
  left: 58%;
  top: 60%; */
  height: 60px;
}
.b-play-store-btn {
  /* position: absolute;
  left: 47%;
  top: 60%; */
  height: 60px;
}
.b-homepage-banner-two {
  margin-top: 50px;
  position: relative;
  height: 230vh;
}

.b-app-store-play-store-btn {
  display: flex;
  position: absolute;
  left: 48%;
  top: 60%;
  column-gap: 10px;
}
.b-home-banner-inner-text-child {
  display: flex;
  font-family: Raleway-Italic;
  color: #fff;
  margin-top: 40px;
  font-size: 22px;
}
.b-main-container {
  overflow: hidden;
  width: 100%;
}

.b-main-container,
.b-main-container * {
  box-sizing: border-box;
  width: 100%;
}
.b-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  padding-top: 100px;
}
.b-div-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  background: #f5c9b3;
  gap: 48px;
  position: relative;
  /* padding: 60px 0 60px 0; */
  z-index: 409;
  width: 1200px;
  border-radius: 20px;
}
.b-div-frame-114 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  /* padding: 0 160px 0 160px; */
  z-index: 412;
}
.b-div-frame-115 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  /* gap: 32px; */
  position: relative;
  /* width: 948px; */
  z-index: 413;
}
.b-span-marketplace-questions {
  flex-basis: auto;
  position: relative;
  color: #000;
  font-family: "Raleway-Italic";
  font-size: 42px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  white-space: nowrap;
  z-index: 411;
}
.b-div-frame-116 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 415;
  width: 100%;
}
.b-span-what-is-frank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 32px;
  color: #281208;
  font-family: "Raleway-Variable";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  white-space: nowrap;
  z-index: 416;
}
.b-frame-175 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 674.b-8px;
  z-index: 537;
}
.b-footer-new {
  width: 100%;
  position: relative;
  background-color: #3026f1;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b-contain {
  padding: 400px 0px 0px 0px;
}

.b-div-line-123 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 1px;
  background: url("../../assets/images/7ad013c4-27f1-4bf0-9a52-de39e7242208.png")
    no-repeat center;
  background-size: cover;
  z-index: 434;
}
.b-footer-new .b-col {
  width: 170px;
  height: auto;
  float: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0px 20px 20px 20px;
}
.b-footer-new .b-col h1 {
  margin: 0;
  padding: 0;
  font-family: "Raleway-Italic";
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  padding: 20px 0px 5px 0px;
  color: #fff;
}
.b-footer-new .b-col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.b-footer-new .b-col ul li {
  color: #fff;
  font-size: 14px;
  font-family: "Raleway-Italic";
  padding: 5px 0px 5px 0px;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
}
.b-social ul li {
  display: inline-block;
  padding-right: 5px !important;
}

.b-footer-new .b-col ul li:hover {
  color: #ffffff;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
}
.b-clearfix {
  clear: both;
}
.b-display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1280px) {
  .b-contain {
    width: 1250px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1139px) {
  .b-contain .b-social {
    width: 1000px;
    display: block;
  }
  .b-social h1 {
    margin: 0px;
  }
}
@media only screen and (max-width: 950px) {
  .b-footer-new .b-col {
    width: 33%;
  }
  .b-footer-new .b-col h1 {
    font-size: 14px;
  }
  .b-footer-new .b-col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 500px) {
  .b-footer-new .b-col {
    width: 50%;
  }
  .b-footer-new .b-col h1 {
    font-size: 14px;
  }
  .b-footer-new .b-col ul li {
    font-size: 13px;
  }
}
@media only screen and (max-width: 340px) {
  .b-footer-new .b-col {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .b-mob-nav {
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 200vh;
  }
}
@media screen and (max-width: 1600px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 190vh;
  }
}
@media screen and (max-width: 1500px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 170vh;
  }
}
.b-home-banner-inner-text-child {
  display: flex;
  font-family: Raleway-Italic;
  color: #fff;
  margin-top: 40px;
  font-size: 20px;
}
@media screen and (max-width: 1400px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 150vh;
  }
  .b-home-banner-inner-text-child {
    display: flex;
    font-family: Raleway-Italic;
    color: #fff;
    margin-top: 40px;
    font-size: 18px;
  }
  .b-main-div {
    height: calc(92vh + 20px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .b-home-banner-inner-div {
    padding: 0px 120px;
    transform: translateY(-0px) !important;
  }

  .b-home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 48px;
    color: #fff;
  }
  .b-home-banner-inner-text-two {
    font-family: "Raleway-Italic";
    font-size: 22px;
    color: #3026f1;
  }
  .b-app-store-btn {
    /* position: absolute;
    left: 63%;
    top: 60%; */
    height: 60px;
  }
  .b-play-store-btn {
    /* position: absolute;
    left: 47%;
    top: 60%; */
    height: 60px;
  }
}

.b-custom-button {
  padding: 10px 20px; /* Adjust padding as needed */
  border: 2px solid #3026f1; /* Border color to match the text */
  border-radius: 10px; /* Rounded corners */
  background-color: white; /* Button background color */
  color: #3026f1; /* Text color */
  font-weight: bold; /* Bold text */
  font-size: 16px; /* Adjust text size as needed */
  text-transform: uppercase; /* Uppercase text */
  position: relative; /* Position for shadow */
  outline: none; /* Remove outline on focus */
  cursor: pointer; /* Pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  font-weight: 900 !important;
}

/* Shadow Effect */
.b-custom-button::after {
  content: "";
  position: absolute;
  bottom: -30px; /* Position the shadow */
  left: 50%; /* Center it horizontally */
  width: 110%; /* Shadow width */
  height: 15px; /* Shadow height */
  background-color: rgba(0, 0, 0, 0.1); /* Shadow color */
  border-radius: 50%; /* Rounded shadow */
  transform: translateX(-50%); /* Center alignment */
  z-index: -1; /* Place it behind the button */
}

.b-custom-button:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

@media screen and (max-width: 1300px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 140vh;
  }
  .b-home-banner-inner-text-child {
    display: flex;
    font-family: Raleway-Italic;
    color: #fff;
    margin-top: 40px;
    font-size: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 120vh;
  }
  .b-home-banner-inner-text-child {
    display: flex;
    font-family: Raleway-Italic;
    color: #fff;
    margin-top: 40px;
    font-size: 16px;
  }
  .b-main-div {
    height: calc(84vh + -33px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .b-home-banner-inner-div {
    padding: 0px 50px;
    transform: translateY(-90px);
  }
  .b-home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 44px;
    color: #fff;
  }
  .b-home-banner-inner-text-two {
    font-family: "Raleway-Italic";
    font-size: 20px;
    color: #3026f1;
  }
}
@media screen and (max-width: 1100px) {
  .b-home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 46px;
    color: #fff;
    width: 100%;
  }
  .b-homepage-banner-two {
    margin-top: 50px;
    position: relative;
    height: 100vh;
  }
}

@media screen and (max-width: 1000px) {
  .b-main-div {
    height: calc(92vh + 20px);
    padding: 0px 40px;
    display: flex;
    align-items: center;
  }
  .b-home-banner-inner-div {
    padding: 0px 120px;
    transform: translateY(-90px);
  }

  .b-home-banner-inner-text {
    font-family: "Raleway-Italic";
    font-weight: 900;
    font-size: 42px;
    color: #fff;
  }
  .b-home-banner-inner-text-two {
    font-family: "Raleway-Italic";
    font-size: 18px;
    color: #3026f1;
    margin-top: 10px;
  }
}

.b-story-background {
  background: url("../../assets/images/FrankIcons/dotted@4x.png") no-repeat
    center;
  background-color: #fad9b6;

  height: 100%;
  background-size: cover;
}

.b-mstpone {
  /* background-color: #f9d9b6; */
  /* background-image: url("../../assets/images/FrankIcons/dotted@4x.png"); */
  min-height: 150px;
  width: 100%;
  background-size: cover;
}

.b-mstpone .b-mst-srcone {
}
.b-mstpone .b-mst-srcone .b-imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}
.b-mstpone .b-mst-srcone .b-imgtxt img {
  width: 100%;
}
.b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
  position: absolute;
  top: 108px;
  right: 240px;
}

.b-mstptwo {
  /* background-image: url("../../assets/images/FrankIcons/dotted@4x.png"); */

  /* background-color: #f9d9b6; */
  /* min-height: 150px; */
  background-size: cover;

  width: 100%;
}
.b-mstptwo .b-mst-srctwo {
}
.b-mstptwo .b-mst-srctwo .b-imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}
.b-mstptwo .b-mst-srctwo .b-imgtxt img {
  width: 100%;
}
.b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
  position: absolute;
  top: 94px;
  left: 218px;
}

.b-mstpthree {
  /* background-color: #f9d9b6; */
  /* background-image: url("../../assets/images/FrankIcons/dotted@4x.png"); */
  min-height: 140px;
  width: 100%;
  background-size: cover;
}
.b-mstpthree .b-mst-srcthree {
}
.b-mstpthree .b-mst-srcthree .b-imgtxt {
  width: 80%;
  display: table;
  margin: auto;
  position: relative;
}
.b-mstpthree .b-mst-srcthree .b-imgtxt img {
  width: 100%;
}
.b-mstpthree .b-mst-srcthree .b-imgtxt .txt {
  position: absolute;
  top: 134px;
  right: 240px;
}

@media (max-width: 1283px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 240px;
    top: 108px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 218px;
    top: 80px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 240px;
    top: 134px;
  }
}
@media (max-width: 1164px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 214px;
    top: 94px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 194px;
    top: 66px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 120px;
    top: 120px;
  }
}
@media (max-width: 1021px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 90px;
    top: 74px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 90px;
    top: 74px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 90px;
    top: 74px;
  }
}
@media (max-width: 1021px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 185px;
    top: 88px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 160px;
    top: 55px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 70px;
    top: 100px;
  }
}
@media (max-width: 890px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 140px;
    top: 82px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 127px;
    top: 38px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 40px;
    top: 96px;
  }
}
@media (max-width: 800px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 100px;
    top: 58px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    left: 99px;
    top: 30px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 10px;
    top: 74px;
  }
}
@media (max-width: 768px) {
  .b-mstpone .b-mst-srcone .b-imgtxt .b-txt {
    right: 0px;
  }
  .b-mstptwo .b-mst-srctwo .b-imgtxt .b-txt {
    right: 0px;
  }
  .b-mstpthree .b-mst-srcthree .b-imgtxt .b-txt {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .b-mstpone {
    display: flex;
  }
  .b-mstptwo {
    display: flex;
  }
  .b-mstpthree {
    display: flex;
  }
}
@media (max-width: 480px) {
}

.b-vous-voulez {
  position: absolute;
  text-align: left;
  font-weight: 800;
  top: 25%;
  right: -50%;
  font-size: 22px;
  color: #3026f1;
  font-family: "Raleway-Italic";
}
.b-vous-voyez-div {
  position: absolute;
  top: 30px;
  right: 38%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.b-vous-voyez-two-div {
  position: absolute;
  top: 90px !important;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-vous-voyez-two {
  position: absolute;
  text-align: left;
  font-weight: 800;
  top: 20%;
  left: 23%;
  font-size: 22px;
  color: #fff;
  font-family: "Raleway-Italic";
}

.b-vous-voyez-three {
  position: absolute;
  text-align: left;
  font-weight: 800;
  top: 25%;
  right: -50%;
  font-size: 18px;
  color: #3026f1;
  font-family: "Raleway-Italic";
}

.b-vous-voyez-three-div {
  position: absolute;
  top: 105px;
  right: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.b-diagonal-border {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust based on your needs */
  background: 
    url('../../assets/images/FrankIcons/dotted@4x.png') repeat, /* Dotted image layer */
    linear-gradient(135deg, #F7D9B6 60%, #ffffff 40%); /* Diagonal gradient */
  background-size: contain; /* Set sizes for each layer */
  background-position: 0 0, center; /* Position each background correctly */
}

/* .diagonal-border::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 160%;
  height: 100vh;
  background-color: #fad9b6;
  transform: rotate(342deg);
  transform-origin: top left;
  background-image: url("../../assets/images/FrankIcons/dotted@4x.png");
  background-size: cover;
  z-index: -1;
} */

.b-section-background {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust based on your content */
  background: linear-gradient(135deg, #F7D9B6 50%, #ffffff 50%); /* Diagonal background */
}

.b-section-background::after {
  content: '';
  position: absolute;
  top: 0;
  right: 5%; /* Start from the middle */
  width: 100%; /* Cover the right half */
  height: 100%;
  background: url('../../assets/images/FrankIcons/dotted@4x.png') repeat;
  opacity: 0.7; /* Adjust opacity if needed */
  pointer-events: none; /* Ignore pointer events */
  z-index: -1; /* Place above the gradient */
  background-size: contain;

}
.b-about-us-bottom{
/* background-image: url("../../assets/images/frank-comment-ca-marche-bg.png");
 */
 background-image: linear-gradient(to bottom right, #3026F1 50%, #ee6931 50%);
  background-size: cover;
  background-position: center;
  height: 10%; 
  width: 100%; /* Set width as needed */
  margin-top: 20px;
}